import {
    FormControl,
    FormHelperText,
    InputLabel,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { useContext } from "react";
import { SelectProps } from "@mui/material";
import FormContext from "./FormContext";
import { getHelperText, getValue } from "./helpers";

export interface IGPSelect extends Omit<SelectProps, "onChange"> {
    errorText?: string;
    formControlProps?: object;
    helperText?: string;
    id: string;
    labelProps?: object;
    multiple?: boolean;
    onChange?: Function;
}

function GPSelect(props: IGPSelect) {
    const {
        children,
        displayEmpty,
        errorText,
        formControlProps,
        fullWidth,
        helperText,
        id,
        label,
        labelProps,
        margin,
        multiple,
        onChange,
        size,
        sx,
        value,
        variant,
        ...restProps
    } = props;

    const context = useContext(FormContext);

    const onChangeValue = (event: SelectChangeEvent<any>) => {
        if (!!onChange) {
            onChange({ id, value: event.target.value }, context);
        } else {
            context?.onChange({ id, value: event.target.value });
        }
    };

    const { inputHelperText, isError } = getHelperText(
        id,
        context,
        errorText,
        helperText
    );

    let ILabelProps = {};
    if (displayEmpty) {
        ILabelProps = { shrink: true };
    }
    ILabelProps = { ...ILabelProps, ...labelProps };

    return (
        <FormControl
            {...formControlProps}
            error={isError}
            fullWidth={fullWidth}
            margin={margin}
            size={size}
            sx={sx}
            variant={variant}
        >
            {!!label && (
                <InputLabel {...ILabelProps} id={`${id}-label`}>
                    {label}
                </InputLabel>
            )}
            <Select
                {...restProps}
                displayEmpty={displayEmpty}
                labelId={`${id}-label`}
                value={getValue(id, context, value)}
                label={label}
                multiple={multiple}
                onChange={onChangeValue}
            >
                {children}
            </Select>
            {inputHelperText.length > 0 && (
                <FormHelperText>{inputHelperText}</FormHelperText>
            )}
        </FormControl>
    );
}

GPSelect.defaultProps = {
    errorText: "",
    formControlProps: {},
    helperText: "",
    labelProps: {},
    size: "small",
};

export default GPSelect;

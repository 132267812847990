import { PermissionModel } from "./interfaces";
import types from "./types";
import { request } from "api/actions";
import { AxiosResponse } from "axios";

export const logout = () =>
    request(types.LOGOUT_REQUEST, types.LOGOUT_FAILED, types.LOGOUT_SUCCESS);

export const me = () =>
    request(
        types.ME_REQUEST,
        types.ME_FAILED,
        types.ME_SUCCESS,
        undefined,
        {},
        undefined,
        (response: AxiosResponse) => {
            return {
                ...response,
                data: {
                    ...response.data,
                    data: {
                        ...response.data.data,
                        permissions: response.data.data.permissions.map(
                            (item: PermissionModel) => item.name
                        ),
                    },
                },
            };
        }
    );

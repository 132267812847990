export const FETCH_FAILED = "SELLERS/FETCH_FAILED";
export const FETCH_REQUEST = "SELLERS/FETCH_REQUEST";
export const FETCH_SUCCESS = "SELLERS/FETCH_SUCCESS";

export const TABLE_UPDATE = "SELLERS/TABLE_UPDATE";

const types = {
    DESTROY_FAILED: "SELLERS/DESTROY_FAILED",
    DESTROY_REQUEST: "SELLERS/DESTROY_REQUEST",
    DESTROY_SUCCESS: "SELLERS/DESTROY_SUCCESS",

    FETCH_FAILED,
    FETCH_REQUEST,
    FETCH_SUCCESS,

    FETCH_ALL_REQUEST: "SELLERES/FETCH_ALL_REQUEST",
    FETCH_ALL_FAILED: "SELLERES/FETCH_ALL_FAILED",
    FETCH_ALL_SUCCESS: "SELLERES/FETCH_ALL_SUCCESS",

    GET_FAILED: "SELLERS/GET_FAILED",
    GET_REQUEST: "SELLERS/GET_REQUEST",
    GET_SUCCESS: "SELLERS/GET_SUCCESS",

    STORE_FAILED: "SELLERS/STORE_FAILED",
    STORE_REQUEST: "SELLERS/STORE_REQUEST",
    STORE_SUCCESS: "SELLERS/STORE_SUCCESS",

    UPDATE_FAILED: "SELLERS/UPDATE_FAILED",
    UPDATE_REQUEST: "SELLERS/UPDATE_REQUEST",
    UPDATE_SUCCESS: "SELLERS/UPDATE_SUCCESS",

    TABLE_UPDATE,
};

export default types;

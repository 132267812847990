import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const requests = {
    destroy: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.delete(`/potential-clients/${url.id}`, config),
    fetch: (config: AxiosRequestConfig) =>
        api.get(`/potential-clients`, config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/potential-clients/${url.id}`, config),
    store: (data: object, config: AxiosRequestConfig) =>
        api.post(`/potential-clients`, data, config),
    update: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`/potential-clients/${url.id}`, data, config),
    patch: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.patch(`/potential-clients/${url.id}`, data, config),
    getLetter: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/potential-clients/${url.id}/letter`, {
            ...config,
            responseType: "blob",
        }),
    bulkLetters: (data: object, config: AxiosRequestConfig) =>
        api.put(`/potential-clients/bulk/letters`, data, {
            ...config,
            responseType: "blob",
        }),
    bulkStatus: (data: object, config: AxiosRequestConfig) =>
        api.put(`/potential-clients/bulk/status`, data, config),
    bulkSeller: (data: object, config: AxiosRequestConfig) =>
        api.put(`/potential-clients/bulk/seller`, data, config),
};

export default requests;

import useRequest from "api/useRequest";
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";
import { Form, Loader, Select, Settings, SettingsItem } from "ui";
import { IRoles } from "../interfaces";
import { IFormContext } from "ui/Form/FormContext";
import { accessFetch, accessUpdate, locationsRoles } from "../actions";
import PermissionsTable from "app/Account/Users/tables/PermissionsTable";
import { permissionsFetch } from "app/Account/Users/actions";
import { IFormChangeResponse, IGPFormRef } from "ui/Form/Form";
import { MenuItem } from "@mui/material";
import trans from "helpers/trans";
import { AxiosResponse } from "axios";
import { UserPermission } from "app/Account/Users/interfaces";
import { EventLocation } from "../types";

type TEventPermissionsForm = {
    applicationKey: string;
    location: EventLocation;
    userId: number;
};

export type TEventPermissionsFormRef = {
    onSubmit: Function;
};

const EventPermissionsForm = forwardRef<
    TEventPermissionsFormRef,
    TEventPermissionsForm
>(({ applicationKey, location, userId }, ref) => {
    const formRef = useRef<IGPFormRef | null>();
    const [selected, setSelected] = useState<number[]>([]);
    const [userRole, setUserRole] = useState<number>();
    const [userLoaded, setUserLoaded] = useState<boolean>(false);
    const { request } = useRequest();

    useImperativeHandle(ref, () => ({
        onSubmit: handleSubmitForm,
    }));

    const { currentData: permissions, isPending: isPendingPermissions } =
        useRequest(permissionsFetch(applicationKey));

    const { currentData: rolesData, isPending: isPendingRoles } = useRequest(
        locationsRoles(location.id, applicationKey, {
            params: { _sort: { name: "asc" }, _with: "permissions_ids" },
        })
    );

    const { reload: reloadUser } = useRequest(
        accessFetch(
            userId,
            applicationKey,
            {
                params: {
                    _filters: {
                        filter_event_access_has_location_id: location.id,
                    },
                    _with: ["user_roles"],
                },
            },
            (response: AxiosResponse) => {
                if (
                    response.status === 200 &&
                    response.data.data.user_roles.length > 0
                ) {
                    setUserRole(response.data.data.user_roles[0].role_id);
                    setSelected(
                        response.data.data.user_permissions.map(
                            (item: UserPermission) => item.permission_id
                        )
                    );
                }
                setUserLoaded(true);
            }
        )
    );

    useEffect(() => {
        formRef.current?.onChange({ id: "permissions", value: selected });
    }, [selected]);

    const roles = useMemo(() => {
        if (rolesData) {
            return rolesData.data.map((item: IRoles) => ({
                ...item,
                permissions: item.permissions_ids,
            }));
        }
        return [];
    }, [rolesData]);

    const handleSubmitForm = (
        event: React.FormEvent<HTMLFormElement | HTMLButtonElement>,
        force: boolean = false
    ) => {
        formRef.current?.onSubmit(event, force);
    };

    const handleOnChangeRole = (
        data: IFormChangeResponse,
        context: IFormContext
    ) => {
        const role = roles.find((item: IRoles) => item.id === data.value);

        if (role === undefined) {
            return;
        }

        context.onChange([
            data,
            { id: "permissions", value: role.permissions },
        ]);
    };

    const handleSubmit = (data: any) => {
        setUserLoaded(false);
        request(
            accessUpdate(userId, data, undefined, (response: AxiosResponse) => {
                if (response.status === 204) {
                    reloadUser();
                }
            })
        );
    };

    if (isPendingPermissions || isPendingRoles || !userLoaded) {
        return <Loader />;
    }

    return (
        <Form
            data={{}}
            fields={{
                _app: { default: applicationKey },
                location_id: { default: location.id },
                role_id: { default: userRole },
                permissions: {
                    default: selected,
                },
            }}
            errors={[]}
            onSubmit={handleSubmit}
            ref={formRef}
            unsaved={false}
        >
            {(context: IFormContext) => (
                <>
                    <Settings sx={{ mb: 2 }}>
                        <SettingsItem
                            label={trans(
                                "admin.account.users.userPermissionsForm.role"
                            )}
                        >
                            <Select
                                fullWidth
                                id="role_id"
                                onChange={handleOnChangeRole}
                            >
                                {roles.map((item: IRoles, idx: number) => (
                                    <MenuItem
                                        value={item.id}
                                        key={`role-${idx}`}
                                        id={`role-${idx}`}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </SettingsItem>
                    </Settings>

                    {!!context.data.role_id && (
                        <PermissionsTable
                            permissions={permissions.data}
                            selected={context.data.permissions}
                            onChange={setSelected}
                        />
                    )}
                </>
            )}
        </Form>
    );
});

export default EventPermissionsForm;

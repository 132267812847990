export type UserRole = {
    role_id: number;
    user_id: number;
    location_id: number;
};

export const TABLE_UPDATE = "ACCOUNT_USERS/TABLE_UPDATE";
export const FETCH_SUCCESS = "ACCOUNT_USERS/FETCH_SUCCESS";

const types = {
    DESTROY_FAILED: "ACCOUNT_USERS/DESTROY_FAILED",
    DESTROY_REQUEST: "ACCOUNT_USERS/DESTROY_REQUEST",
    DESTROY_SUCCESS: "ACCOUNT_USERS/DESTROY_SUCCESS",

    FETCH_FAILED: "ACCOUNT_USERS/FETCH_FAILED",
    FETCH_REQUEST: "ACCOUNT_USERS/FETCH_REQUEST",
    FETCH_SUCCESS,

    GET_FAILED: "ACCOUNT_USERS/GET_FAILED",
    GET_REQUEST: "ACCOUNT_USERS/GET_REQUEST",
    GET_SUCCESS: "ACCOUNT_USERS/GET_SUCCESS",

    ROLES_GET_FAILED: "ROLES_USERS/GET_FAILED",
    ROLES_GET_REQUEST: "ROLES_USERS/GET_REQUEST",
    ROLES_GET_SUCCESS: "ROLES_USERS/GET_SUCCESS",

    PATCH_FAILED: "ACCOUNT_USERS/PATCH_FAILED",
    PATCH_REQUEST: "ACCOUNT_USERS/PATCH_REQUEST",
    PATCH_SUCCESS: "ACCOUNT_USERS/PATCH_SUCCESS",

    STORE_FAILED: "ACCOUNT_USERS/STORE_FAILED",
    STORE_REQUEST: "ACCOUNT_USERS/STORE_REQUEST",
    STORE_SUCCESS: "ACCOUNT_USERS/STORE_SUCCESS",

    ACCESS_FETCH_FAILED: "ACCOUNT_USERS/ACCESS_FETCH_FAILED",
    ACCESS_FETCH_REQUEST: "ACCOUNT_USERS/ACCESS_FETCH_REQUEST",
    ACCESS_FETCH_SUCCESS: "ACCOUNT_USERS/ACCESS_FETCH_SUCCESS",

    ACCESS_UPDATE_FAILED: "ACCOUNT_USERS/ACCESS_UPDATE_FAILED",
    ACCESS_UPDATE_REQUEST: "ACCOUNT_USERS/ACCESS_UPDATE_REQUEST",
    ACCESS_UPDATE_SUCCESS: "ACCOUNT_USERS/ACCESS_UPDATE_SUCCESS",

    PERMISSIONS_GET_FAILED: "ACCOUNT_USERS/PERMISSIONS_GET_FAILED",
    PERMISSIONS_GET_REQUEST: "ACCOUNT_USERS/PERMISSIONS_GET_REQUEST",
    PERMISSIONS_GET_SUCCESS: "ACCOUNT_USERS/PERMISSIONS_GET_SUCCESS",

    PERMISSIONS_UPDATE_FAILED: "ACCOUNT_USERS/PERMISSIONS_UPDATE_FAILED",
    PERMISSIONS_UPDATE_REQUEST: "ACCOUNT_USERS/PERMISSIONS_UPDATE_REQUEST",
    PERMISSIONS_UPDATE_SUCCESS: "ACCOUNT_USERS/PERMISSIONS_UPDATE_SUCCESS",

    UPDATE_FAILED: "ACCOUNT_USERS/UPDATE_FAILED",
    UPDATE_REQUEST: "ACCOUNT_USERS/UPDATE_REQUEST",
    UPDATE_SUCCESS: "ACCOUNT_USERS/UPDATE_SUCCESS",
    TABLE_UPDATE,
};

export default types;

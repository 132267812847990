import { FC, useRef } from "react";
import { Box } from "@mui/material";
import { Modal, ModalTitle, ModalContent, ModalActions, Button } from "ui";
import { useDispatch } from "react-redux";
import { modalHide } from "app/App/actions";
import { InventoryLocation } from "../types";
import InventoryPermissionsForm from "../forms/InventoryPermissionsForm";

type TInventoryPermissionsModal = {
    applicationKey: string;
    location: InventoryLocation;
    userId: number;
    onClose: Function;
};

const InventoryPermissionsModal: FC<TInventoryPermissionsModal> = ({
    applicationKey,
    location,
    onClose,
    userId,
}) => {
    const dispatch = useDispatch();
    const formRef = useRef<any>();

    const handleClose = () => {
        onClose();
        dispatch(modalHide("InventoryPermissionsModal"));
    };

    const handleSaveForm = (
        event: React.FormEvent<HTMLFormElement | HTMLButtonElement>
    ) => {
        formRef.current?.onSubmit(event);
    };

    return (
        <Modal open maxWidth="md" fullWidth onClose={handleClose}>
            <ModalTitle onClose={handleClose}>{location.name}</ModalTitle>

            <ModalContent sx={{ p: 2 }}>
                <InventoryPermissionsForm
                    applicationKey={applicationKey}
                    location={location}
                    userId={userId}
                    ref={formRef}
                />
            </ModalContent>
            <ModalActions>
                <Box display="flex" width="100%" textAlign="right">
                    <Button
                        onClick={handleClose}
                        color="inherit"
                        variant="text"
                        sx={{ mr: 1 }}
                    >
                        Cancel
                    </Button>
                    <Button sx={{ flex: 1 }} onClick={handleSaveForm}>
                        Save
                    </Button>
                </Box>
            </ModalActions>
        </Modal>
    );
};

export default InventoryPermissionsModal;

import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

type RequestPermissionsFetch = {
    applicationKey: string;
};

const requests = {
    destroy: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.delete(`/account/users/${url.id}`, config),
    fetch: (config: AxiosRequestConfig) => api.get(`/account/users`, config),
    getUser: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/account/users/${url.id}`, config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/account/users/${url.id}`, config),
    store: (data: object, config: AxiosRequestConfig) =>
        api.post(`/account/users`, data, config),
    update: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`/account/users/${url.id}`, data, config),
    patch: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.patch(`/account/users/${url.id}`, data, config),

    access: {
        fetch: (config: AxiosRequestConfig, url: RequestUrlId) =>
            api.get(`/users/accesses/${url.id}`, config),
        update: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
            api.put(`/users/accesses/${url.id}`, data, config),
    },
    permissions: {
        fetch: (config: AxiosRequestConfig, url: RequestPermissionsFetch) =>
            api.get(`/permissions/${url.applicationKey}/all`, config),
    },
    roles: {
        fetch: (config: AxiosRequestConfig) => api.get(`/roles/all`, config),
    },

    // permissionsFetch: (config: AxiosRequestConfig) =>
    //     api.get(`/account/permission`, config),
    // permissionsUpdate: (
    //     data: object,
    //     config: AxiosRequestConfig,
    //     url: RequestUrlId
    // ) => api.put(`/account/permissions/${url.id}`, data, config),
};

export default requests;

import api from "./api";
import { request } from "api/apiSaga";
import { snackbarShow } from "app/App/actions";
import trans from "helpers/trans";
import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";
import types from "./types";

function* destroy(action: AnyAction): any {
    const response = yield call(request, api.destroy, action, "delete");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("admin.account.users.saga.content.destroy"),
            })
        );
    }
}

function* fetch(action: AnyAction) {
    yield call(request, api.fetch, action);
}

function* get(action: AnyAction) {
    yield call(request, api.get, action);
}

function* patch(action: AnyAction): any {
    const response = yield call(request, api.patch, action, "patch");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("admin.account.users.saga.content.update"),
            })
        );
    }
    if (response.status === 422) {
        yield put(
            snackbarShow({
                content: response.data.message,
                severity: "error",
                errors: response.data.errors,
            })
        );
    }
}

function* store(action: AnyAction): any {
    const response = yield call(request, api.store, action, "post");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("admin.account.users.saga.content.store"),
            })
        );
    }
}

function* update(action: AnyAction): any {
    const response = yield call(request, api.update, action, "put");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("admin.account.users.saga.content.update"),
            })
        );
    }
}

function* accessFetch(action: AnyAction) {
    yield call(request, api.access.fetch, action);
}

function* accessUpdate(action: AnyAction) {
    yield call(request, api.access.update, action, "put");
}

function* permissionsFetch(action: AnyAction) {
    yield call(request, api.permissions.fetch, action);
}

function* rolesFetch(action: AnyAction) {
    yield call(request, api.roles.fetch, action);
}

function* permissionsUpdate(action: AnyAction): any {
    const response = yield call(request, api.fetch, action, "put");
    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("admin.account.users.saga.content.permsUpdate"),
            })
        );
    }
}

export default function* saga() {
    yield takeLatest(types.DESTROY_REQUEST, destroy);
    yield takeLatest(types.FETCH_REQUEST, fetch);
    yield takeLatest(types.GET_REQUEST, get);
    yield takeLatest(types.PATCH_REQUEST, patch);
    yield takeLatest(types.STORE_REQUEST, store);
    yield takeLatest(types.UPDATE_REQUEST, update);

    yield takeLatest(types.ACCESS_FETCH_REQUEST, accessFetch);
    yield takeLatest(types.ACCESS_UPDATE_REQUEST, accessUpdate);
    yield takeLatest(types.PERMISSIONS_GET_REQUEST, permissionsFetch);
    yield takeLatest(types.ROLES_GET_REQUEST, rolesFetch);
    yield takeLatest(types.PERMISSIONS_UPDATE_REQUEST, permissionsUpdate);
}

import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";
import types from "./types";
import { IPotentialClients } from "./interfaces";

export const potentialClientsCreate = (
    data: IPotentialClients,
    onResponse?: Function
) =>
    request(
        types.STORE_REQUEST,
        types.STORE_FAILED,
        types.STORE_SUCCESS,
        "potentialClientsCreate",
        { data },
        onResponse
    );

export const potentialClientsFetch = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        "potentialClientsFetch",
        {
            config,
        }
    );

export const potentialClientsDelete = (id: number, onResponse?: Function) =>
    request(
        types.DESTROY_REQUEST,
        types.DESTROY_FAILED,
        types.DESTROY_SUCCESS,
        "potentialClientsDelete",
        {
            url: {
                id,
            },
        },
        onResponse
    );

export const potentialClientsGet = (id: number) =>
    request(
        types.GET_REQUEST,
        types.GET_FAILED,
        types.GET_SUCCESS,
        "potentialClientsGet",
        {
            url: {
                id,
            },
        }
    );

export const potentialClientsUpdate = (
    id: number,
    data: any,
    onResponse?: Function
) => {
    return request(
        types.UPDATE_REQUEST,
        types.UPDATE_FAILED,
        types.UPDATE_SUCCESS,
        "potentialClientsUpdate",
        { data, url: { id } },
        onResponse
    );
};

export const potentialClientsPatch = (
    id: number,
    data: any,
    onResponse?: Function
) => {
    return request(
        types.PATCH_REQUEST,
        types.PATCH_FAILED,
        types.PATCH_SUCCESS,
        "potentialClientsPatch",
        { data, url: { id } },
        onResponse
    );
};

export const potentialClientsGetLetter = (id: number, onResponse?: Function) =>
    request(
        types.GET_LETTER_REQUEST,
        types.GET_LETTER_FAILED,
        types.GET_LETTER_SUCCESS,
        "potentialClientsGetLetter",
        {
            url: {
                id,
            },
        },
        onResponse
    );

export const potentialClientsBulkLetters = (
    data: { ids: number[] },
    onResponse?: Function
) =>
    request(
        types.BULK_LETTERS_REQUEST,
        types.BULK_LETTERS_FAILED,
        types.BULK_LETTERS_SUCCESS,
        "potentialClientsBulkLetters",
        {
            data,
        },
        onResponse
    );

export const potentialClientsBulkStatus = (
    data: { ids: number[]; status: string },
    onResponse?: Function
) =>
    request(
        types.BULK_STATUS_REQUEST,
        types.BULK_STATUS_FAILED,
        types.BULK_STATUS_SUCCESS,
        "potentialClientsBulkStatus",
        {
            data,
        },
        onResponse
    );

export const potentialClientsBulkSeller = (
    data: { ids: number[]; seller_id: number },
    onResponse?: Function
) =>
    request(
        types.BULK_SELLER_REQUEST,
        types.BULK_SELLER_FAILED,
        types.BULK_SELLER_SUCCESS,
        "potentialClientsBulkSeller",
        {
            data,
        },
        onResponse
    );

export const potentialClientsTableUpdate = (action: string, data: any) => ({
    type: types.TABLE_UPDATE,
    action,
    data,
});

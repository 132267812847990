import api from "./api";
import { request } from "api/apiSaga";
import { call, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";
import types from "./types";

function* locationsFetch(action: AnyAction) {
    yield call(request, api.locations.fetch, action);
}

function* locationsRolesGet(action: AnyAction) {
    yield call(request, api.locations.roles, action);
}

function* accessDestroy(action: AnyAction) {
    yield call(request, api.access.destroy, action, "delete");
}

function* accessFetch(action: AnyAction) {
    yield call(request, api.access.fetch, action);
}

function* accessUpdate(action: AnyAction) {
    yield call(request, api.access.update, action, "put");
}

export default function* saga() {
    yield takeLatest(types.ACCESS_DESTROY_REQUEST, accessDestroy);
    yield takeLatest(types.ACCESS_FETCH_REQUEST, accessFetch);
    yield takeLatest(types.ACCESS_UPDATE_REQUEST, accessUpdate);
    yield takeLatest(types.LOCATIONS_FETCH_REQUEST, locationsFetch);
    yield takeLatest(types.LOCATIONS_ROLES_REQUEST, locationsRolesGet);
}

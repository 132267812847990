export const TABLE_UPDATE = "EVENT_LOCATIONS/TABLE_UPDATE";
export const FETCH_SUCCESS = "EVENT_LOCATIONS/FETCH_SUCCESS";

const types = {
    DESTROY_FAILED: "EVENT_LOCATIONS/DESTROY_FAILED",
    DESTROY_REQUEST: "EVENT_LOCATIONS/DESTROY_REQUEST",
    DESTROY_SUCCESS: "EVENT_LOCATIONS/DESTROY_SUCCESS",

    FETCH_FAILED: "EVENT_LOCATIONS/FETCH_FAILED",
    FETCH_REQUEST: "EVENT_LOCATIONS/FETCH_REQUEST",
    FETCH_SUCCESS,

    GET_FAILED: "EVENT_LOCATIONS/GET_FAILED",
    GET_REQUEST: "EVENT_LOCATIONS/GET_REQUEST",
    GET_SUCCESS: "EVENT_LOCATIONS/GET_SUCCESS",

    PATCH_FAILED: "EVENT_LOCATIONS/PATCH_FAILED",
    PATCH_REQUEST: "EVENT_LOCATIONS/PATCH_REQUEST",
    PATCH_SUCCESS: "EVENT_LOCATIONS/PATCH_SUCCESS",
    
    STORE_FAILED: "EVENT_LOCATIONS/STORE_FAILED",
    STORE_REQUEST: "EVENT_LOCATIONS/STORE_REQUEST",
    STORE_SUCCESS: "EVENT_LOCATIONS/STORE_SUCCESS",

    UPDATE_FAILED: "EVENT_LOCATIONS/UPDATE_FAILED",
    UPDATE_REQUEST: "EVENT_LOCATIONS/UPDATE_REQUEST",
    UPDATE_SUCCESS: "EVENT_LOCATIONS/UPDATE_SUCCESS",
    TABLE_UPDATE,
};

export default types;

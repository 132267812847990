import SellersTable from "./reducers/SellersTableReducer";
import SellersSaga from "./saga";
import AsyncComponent from "ui/AsyncComponent";
import { registerRoutings } from "rootRouting";
import store from "rootStore";

export const permissionKey = "sellers";
export const url = "/sellers";

const getConfig = (permissions) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config = {
        permissions: {
            create: false,
            delete: false,
            read: false,
            update: false,
            letters: true,
        },
        routing: [],
        url,
    };

    let routing = [];

    if (modulePermissions.indexOf(permissionKey + ".create") !== -1) {
        config.permissions.create = true;

        routing.push({
            path: `${url}/create`,
            element: <AsyncComponent path="Sellers/pages/SellersCreatePage" />,
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
        config.permissions.delete = true;
    }
    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        store.reducerManager.add("SellersTable", SellersTable);
        store.injectSaga("SellersSaga", SellersSaga);

        routing.push({
            path: url,
            element: <AsyncComponent path="Sellers/pages/SellersIndexPage" />,
        });
        if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
            config.permissions.update = true;

            routing.push({
                path: `${url}/:id/edit`,
                element: (
                    <AsyncComponent path="Sellers/pages/SellersEditPage" />
                ),
            });
        }
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;

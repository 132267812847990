import { FC } from "react";
import { Box, Typography } from "@mui/material";

const NotFound: FC = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
        >
            <Typography fontSize={72}>404</Typography>
            <Typography fontSize={32}>Page Not Found</Typography>
        </Box>
    );
};

export default NotFound;

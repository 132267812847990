import { FC, useEffect, useMemo, useState } from "react";
import { Divider, Box, Typography } from "@mui/material";
import {
    Modal,
    ModalTitle,
    ModalContent,
    ModalActions,
    RequestMessage,
    Search,
} from "ui";
import { List, ListItem, ListItemText } from "@mui/material";
import useRequest from "api/useRequest";
import { locationsFetch } from "../actions";
import { useDispatch } from "react-redux";
import { modalHide } from "app/App/actions";

interface IEventLocationsSelectModal {
    applicationKey: string;
    onClick: Function;
}
const EventLocationsSelectModal: FC<IEventLocationsSelectModal> = ({
    applicationKey,
    onClick,
}) => {
    const dispatch = useDispatch();
    const [searchVal, setSearchValue] = useState<string>("");

    const { currentData, message, status, isError, isLoading, request } =
        useRequest();

    const locationList = useMemo(() => {
        if (!currentData) return [];
        return currentData.data;
    }, [currentData]);

    useEffect(() => {
        if (searchVal.length > 0) {
            request(
                locationsFetch(applicationKey, {
                    params: {
                        _search: searchVal,
                        _sort: { name: "asc" },
                    },
                })
            );
        } else {
            setSearchValue("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchVal]);

    const handleClose = () => {
        dispatch(modalHide("EventLocationsSelectModal"));
    };

    const renderContent = () => {
        if (isLoading)
            return (
                <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
                    Loading
                </Typography>
            );
        if (isError)
            return status === 404 ? (
                <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
                    No found
                </Typography>
            ) : (
                <RequestMessage status={status} message={message} />
            );
        if (searchVal.length === 0 || locationList.length === 0)
            return (
                <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
                    No results
                </Typography>
            );
        return (
            <List component="nav" sx={{ pt: 0 }}>
                {locationList.map((item: any) => {
                    return (
                        <Box key={`${item.id}`}>
                            <ListItem
                                button
                                onClick={(event) => onClick(event, item)}
                            >
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                    }}
                                >
                                    <ListItemText>{item.name}</ListItemText>
                                </Box>
                            </ListItem>
                            <Divider />
                        </Box>
                    );
                })}
            </List>
        );
    };

    return (
        <Modal
            open
            maxWidth="xs"
            fullWidth
            PaperProps={{ sx: { position: "fixed", top: 0 } }}
            onClose={handleClose}
        >
            <ModalTitle onClose={handleClose}>Choose Location</ModalTitle>
            <ModalActions>
                <Search
                    onChange={(val: string) => setSearchValue(val)}
                    value={searchVal}
                    open={true}
                    inputSX={{ width: "100%" }}
                />
            </ModalActions>
            <ModalContent sx={{ pt: 0, px: 2 }}>{renderContent()}</ModalContent>
        </Modal>
    );
};

export default EventLocationsSelectModal;

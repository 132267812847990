import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

type RequestDestroyAccess = {
    id: number;
    locationId: number;
};

const requests = {
    access: {
        destroy: (config: AxiosRequestConfig, url: RequestDestroyAccess) =>
            api.delete(
                `/inventory/users/accesses/${url.id}/location/${url.locationId}`,
                config
            ),
        fetch: (config: AxiosRequestConfig, url: RequestUrlId) =>
            api.get(`/inventory/users/accesses/${url.id}`, config),
        update: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
            api.put(`/inventory/users/accesses/${url.id}`, data, config),
    },
    locations: {
        fetch: (config: AxiosRequestConfig) =>
            api.get(`/inventory/locations/all`, config),
        roles: (config: AxiosRequestConfig, url: RequestUrlId) =>
            api.get(`/inventory/locations/${url.id}/roles`, config),
    },
};

export default requests;

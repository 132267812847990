import { removeDiacritics } from "./diacritics";

const currencies = {
    NOK: "kr",
    SEK: "kr",
};

// declare global {
//     interface Number {
//         format(n: number, x?: number): string;
//         price(currency: string, position: string, format: number): string;
//     }

//     interface String {
//         removeDiacritics(): string;
//     }
// }

// eslint-disable-next-line no-extend-native
Number.prototype.format = function (n, x) {
    var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$& ");
};

// eslint-disable-next-line no-extend-native
Number.prototype.price = function (currency, position = "right", format = 2) {
    let value = this;

    if (isNaN(this)) {
        value = 0;
    }

    value = value / 100;

    if (currency === undefined) {
        return value.format(format);
    }

    if (position === "right")
        return value.format(format) + " " + currencies[currency];
    else return currencies[currency] + " " + value.format(format);
};

// eslint-disable-next-line no-extend-native
String.prototype.removeDiacritics = function () {
    return removeDiacritics(this);
};

import { createTheme } from "@mui/material/styles";

const themeTemp = createTheme();
const gray = themeTemp.palette.augmentColor({
    color: { main: "#3E3E3F", contrastText: "#FFF" },
    name: "gray",
});

const gray2 = themeTemp.palette.augmentColor({
    color: {
        main: themeTemp.palette.grey[600],
        contrastText: "#fff",
    },
    name: "gray2",
});

const light = themeTemp.palette.augmentColor({
    color: {
        main: "#fff",
        contrastText: "#000",
    },
    name: "white",
});

const theme = createTheme({
    palette: {
        primary: {
            main: "#36845B",
        },
        secondary: {
            main: "#3498DB",
        },
        gray,
        gray2,
        light,
    },
    transitions: {
        // So we have `transition: none;` everywhere
        create: () => "none",
    },
});
theme.sideNav = {
    background: gray.main,
    color: gray.contrastText,
    selectedBg: gray.light,
    width: 270,
};

export default theme;

import { UsersModel } from "./interfaces";
import types from "./types";

import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";

export const usersCreate = (data: UsersModel, onResponse?: Function) =>
    request(
        types.STORE_REQUEST,
        types.STORE_FAILED,
        types.STORE_SUCCESS,
        "usersCreate",
        { data },
        onResponse
    );

export const usersDelete = (id: number, onResponse?: Function) =>
    request(
        types.DESTROY_REQUEST,
        types.DESTROY_FAILED,
        types.DESTROY_SUCCESS,
        "usersDelete",
        {
            url: {
                id,
            },
        },
        onResponse
    );

export const usersFetch = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        "usersFetch",
        {
            config,
        }
    );

export const usersGet = (
    id: number,
    config?: AxiosRequestConfig,
    onResponse?: Function
) =>
    request(
        types.GET_REQUEST,
        types.GET_FAILED,
        types.GET_SUCCESS,
        "usersGet",
        {
            config,
            url: {
                id,
            },
        },
        onResponse
    );

export const usersPatch = (id: number, data: any, onResponse?: Function) => {
    return request(
        types.PATCH_REQUEST,
        types.PATCH_FAILED,
        types.PATCH_SUCCESS,
        "usersPatch",
        { data, url: { id } },
        onResponse
    );
};

export const usersTableUpdate = (action: string, data: any) => ({
    type: types.TABLE_UPDATE,
    action,
    data,
});

export const usersUpdate = (id: number, data: any, onResponse?: Function) => {
    return request(
        types.UPDATE_REQUEST,
        types.UPDATE_FAILED,
        types.UPDATE_SUCCESS,
        "usersUpdate",
        { data, url: { id } },
        onResponse
    );
};

export const accessFetch = (
    ssoId: number,
    app: string,
    config?: AxiosRequestConfig,
    onResponse?: Function
) =>
    request(
        types.ACCESS_FETCH_REQUEST,
        types.ACCESS_FETCH_FAILED,
        types.ACCESS_FETCH_SUCCESS,
        "accessFetch",
        {
            config: { ...config, params: { _app: app, ...config?.params } },
            url: { id: ssoId },
        },
        onResponse
    );

export const accessUpdate = (
    ssoId: number,
    data: any,
    config?: AxiosRequestConfig,
    onResponse?: Function
) =>
    request(
        types.ACCESS_UPDATE_REQUEST,
        types.ACCESS_UPDATE_FAILED,
        types.ACCESS_UPDATE_SUCCESS,
        "accessUpdate",
        {
            config,
            data,
            url: { id: ssoId },
        },
        onResponse
    );

export const permissionsFetch = (
    applicationKey: string,
    config?: AxiosRequestConfig
) =>
    request(
        types.PERMISSIONS_GET_REQUEST,
        types.PERMISSIONS_GET_FAILED,
        types.PERMISSIONS_GET_SUCCESS,
        "permissionsFetch",
        {
            config,
            url: { applicationKey },
        }
    );

export const usersPermissionsUpdate = (
    id: number,
    data: any,
    onResponse?: Function
) => {
    return request(
        types.PERMISSIONS_UPDATE_REQUEST,
        types.PERMISSIONS_UPDATE_FAILED,
        types.PERMISSIONS_UPDATE_SUCCESS,
        "usersPermissionsUpdate",
        { data, url: { id } },
        onResponse
    );
};

export const rolesFetch = (
    applicationKey: string,
    config?: AxiosRequestConfig
) =>
    request(
        types.ROLES_GET_REQUEST,
        types.ROLES_GET_FAILED,
        types.ROLES_GET_SUCCESS,
        "rolesFetch",
        {
            config: {
                ...config,
                params: { _app: applicationKey, ...config?.params },
            },
        }
    );

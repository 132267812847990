import { AnyAction } from "redux";
import api from "./api";
import types from "./types";
import { request } from "api/apiSaga";
import { call, put, takeLatest } from "redux-saga/effects";
import { snackbarShow } from "app/App/actions";
import trans from "helpers/trans";
import { downloadFromRequest } from "helpers/download";

function* destroy(action: AnyAction): any {
    const response = yield call(request, api.destroy, action, "delete");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("admin.potentialClients.saga.content.destroy"),
            })
        );
    }
}

function* fetch(action: AnyAction) {
    yield call(request, api.fetch, action);
}

function* get(action: AnyAction) {
    yield call(request, api.get, action);
}

function* store(action: AnyAction): any {
    const response = yield call(request, api.store, action, "post");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("admin.potentialClients.saga.content.store"),
            })
        );
    }
}

function* update(action: AnyAction): any {
    const response = yield call(request, api.update, action, "put");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("admin.potentialClients.saga.content.update"),
            })
        );
    }
}

function* patch(action: AnyAction): any {
    const response = yield call(request, api.patch, action, "patch");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("admin.potentialClients.saga.content.update"),
            })
        );
    }
    if (response.status === 422) {
        yield put(
            snackbarShow({
                content: response.data.message,
                severity: "error",
                errors: response.data.errors
            })
        );
    }
}

function* getLetter(action: AnyAction): any {
    const response = yield call(request, api.getLetter, action);
    if (response.status === 200) downloadFromRequest(response);
}

function* bulkLetters(action: AnyAction): any {
    const response = yield call(request, api.bulkLetters, action, "put");
    if (response.status === 200) {
        downloadFromRequest(response);
        yield put(
            snackbarShow({
                content: trans(
                    "admin.potentialClients.saga.content.bulkLetters"
                ),
            })
        );
    }
}
function* bulkStatus(action: AnyAction): any {
    const response = yield call(request, api.bulkStatus, action, "put");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans(
                    "admin.potentialClients.saga.content.bulkStatus"
                ),
            })
        );
    }
}
function* bulkSeller(action: AnyAction): any {
    const response = yield call(request, api.bulkSeller, action, "put");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans(
                    "admin.potentialClients.saga.content.bulkSeller"
                ),
            })
        );
    }
}
export default function* saga() {
    yield takeLatest(types.DESTROY_REQUEST, destroy);
    yield takeLatest(types.FETCH_REQUEST, fetch);
    yield takeLatest(types.GET_REQUEST, get);
    yield takeLatest(types.PATCH_REQUEST, patch);
    yield takeLatest(types.STORE_REQUEST, store);
    yield takeLatest(types.UPDATE_REQUEST, update);
    yield takeLatest(types.GET_LETTER_REQUEST, getLetter);
    yield takeLatest(types.BULK_LETTERS_REQUEST, bulkLetters);
    yield takeLatest(types.BULK_STATUS_REQUEST, bulkStatus);
    yield takeLatest(types.BULK_SELLER_REQUEST, bulkSeller);
}

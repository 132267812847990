import usersConfig from "./Users/config";
import applicationsConfig from "./Applications/config";

import IConfig from "interfaces/IConfig";
import { registerModule } from "moduleConfig";
import { registerProjects } from "rootProjects";
import { registerRoutings } from "rootRouting";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const permissionKey = "account";
export const url = `/account`;

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            read: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.length > 0) {
        const routingChildrens = [];

        // register Users module
        const users = usersConfig(modulePermissions, url);
        registerModule(`${permissionKey}.user`, users.config);
        routingChildrens.push(...users.routing);

        // register Applications module
        const applications = applicationsConfig(modulePermissions, url);
        registerModule(`${permissionKey}.application`, applications.config);
        routingChildrens.push(...applications.routing);

        routing.push({
            path: url + "/*",
            children: routingChildrens,
            element: <AsyncComponent path="Account/index" />,
        });

        registerProjects([
            {
                id: permissionKey,
                name: "Account",
                icon: "manage_accounts",
                url: `${url}`,
                priority: 200,
            },
        ]);
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;

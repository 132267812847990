import { FETCH_SUCCESS, TABLE_UPDATE } from "../types";
import { tableResponse, tableUpdate } from "helpers/reducers";

interface IAction {
    type: string;
    response: any;
}

function SelleresTable(
    state = {
        id: "SellersTable",
        columns: [
            {
                field: "user_id",
                headName: "User",
                sort: "asc",
                sortable: false,
            },
            {
                field: "zip_code_ranges.from",
                headName: "From",
                sort: "asc",
                sortable: false,
            },
            {
                field: "zip_code_ranges.to",
                headName: "To",
                sort: "asc",
                sortable: false,
            },
        ],
        filters: {},
        pagination: {
            page: 1,
            per_page: 20,
        },
        search: "",
        selection: {
            id: "id",
            selected: [],
        },
        sort: { name: "asc" },
    },
    action = {} as IAction
) {
    switch (action.type) {
        case FETCH_SUCCESS:
            if (action.response.config.params?._table_id === state.id) {
                return tableResponse(state, action);
            }
            return state;
        case TABLE_UPDATE:
            return tableUpdate(state, action);

        default:
            return state;
    }
}

export default SelleresTable;

import locationsConfig from "./Locations/config";
import locationGroupsConfig from "./LocationGroups/config";
import EventLocationsSelectModal from "./modals/EventLocationsSelectModal";
import EventPermissionsModal from "./modals/EventPermissionsModal";
import EventSaga from "./saga";
import translateConfig from "./Translate/config";

import { registerModule } from "moduleConfig";
import { registerModals } from "modals";
import { registerProjects } from "rootProjects";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const permissionKey = "event";
export const url = `/event`;
export const appName = process.env.REACT_APP_DOMAIN_BASE?.includes("develop") ? "devent" : "event"

interface IEventConfig {
    permissions: {
        create?: boolean;
        delete?: boolean;
        read?: boolean;
        update?: boolean;
        translation?: boolean;
    };
    routing: Array<string>;
    url: string;
}

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IEventConfig = {
        permissions: {
            translation: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.length > 0) {
        const routingChildrens = [];
        store.injectSaga?.("EventSaga", EventSaga);
        registerModals({ EventLocationsSelectModal, EventPermissionsModal });
        // register Locations module
        const locations = locationsConfig(modulePermissions, url);
        registerModule(`${permissionKey}.locations`, locations.config);
        routingChildrens.push(...locations.routing);

        // register LocationGroups module
        const locationGroups = locationGroupsConfig(modulePermissions, url);
        registerModule(
            `${permissionKey}.location_groups`,
            locationGroups.config
        );

        //register Translate module
        if (modulePermissions.indexOf(permissionKey + ".translation") !== -1) {
            config.permissions.translation = true;
            const tr = translateConfig(permissions, url);
            registerModule(`${permissionKey}.translation`, tr.config);
            routingChildrens.push(...tr.routing);
        }
        routingChildrens.push(...locationGroups.routing);
        routing.push({
            path: url + "/*",
            children: routingChildrens,
            element: <AsyncComponent path="Event/index" />,
        });

        registerProjects([
            {
                id: permissionKey,
                name: "Event",
                icon: "event",
                url: `${url}`,
                priority: 400,
            },
        ]);
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;

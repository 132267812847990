import {
    Navigate,
    useLocation,
    useNavigate,
    useRoutes,
} from "react-router-dom";
import NotFound from "app/App/pages/NotFound";
import Dashboard from "app/App/pages/Dashboard";
import { useEffect } from "react";

let routings = [];

export function registerRoutings(data) {
    routings.push(...data);
}

const AppRouting = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const locationElements = location.pathname.split("/");
        if (locationElements.length === 2) {
            const mainRouting = routings.find((item) => {
                return item.path === `/${locationElements[1]}/*`;
            });

            if (mainRouting?.children.length > 0) {
                const navigateTo = mainRouting.children.find((item) => {
                    return (
                        item.path.indexOf(":") === -1 &&
                        item.path.indexOf("create") === -1
                    );
                });

                if (!!navigateTo) {
                    navigate(`/${locationElements[1]}/${navigateTo.path}`);
                }
            }
        }
    }, [location.pathname, navigate]);

    return useRoutes([
        ...routings,
        { path: "/", element: <Navigate to="/dashboard" replace={true} /> },
        { path: "/dashboard", element: <Dashboard /> },
        { path: "*", element: <NotFound /> },
    ]);
};

export default AppRouting;

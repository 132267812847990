import { FETCH_ALL_SUCCESS, FETCH_SUCCESS, TABLE_UPDATE } from "../types";
import { tableResponse, tableUpdate } from "helpers/reducers";
import { MANAGE_TABLE } from "app/ManageTables/types";
import { IGPTable } from "ui/Table/Table";

interface IApplicationsTable extends IGPTable {
    allApplications: any;
}
function ApplicationsTable(
    state: IApplicationsTable = {
        allApplications: [],
        id: "AccountApplicationTable",
        columns: [],
        availableColumns: [],
        filters: {},
        filterSettings: {
            open: false,
            total: 0,
        },
        pagination: {
            page: 1,
            per_page: 20,
            isLastPage: true,
            total: 0,
        },
        search: "",
        selection: {
            id: "id",
            selected: [],
        },
        selectionActions: null,
        sort: { name: "asc" },
        with: [],
    },
    action: any
) {
    switch (action.type) {
        case FETCH_SUCCESS:
            if (action.response.config.params._table_id === state.id) {
                return tableResponse(state, action);
            }
            return state;

        case MANAGE_TABLE:
            if (action.data.id === state.id) {
                return {
                    ...state,
                    ...action.data,
                };
            }
            return state;

        case TABLE_UPDATE:
            return tableUpdate(state, action);
        case FETCH_ALL_SUCCESS:
            return {
                ...state,
                allApplications: action.response.data.data,
            };
        default:
            return state;
    }
}

export default ApplicationsTable;

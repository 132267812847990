import IConfig from "interfaces/IConfig";
import ManageTables from "./saga";

import loadable from "@loadable/component";
import { registerModals } from "modals";
import store from "rootStore";
import { permissionsType } from "types/configTypes";

export const permissionKey = "table-management";

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );
    const config: IConfig = {
        permissions: {
            create: false,
            delete: false,
            read: true,
            update: false,
        },
        routing: [],
        url: "",
    };

    store.injectSaga?.("ManageTables", ManageTables);

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;
        registerModals({
            TableModal: loadable(() => import("ui/Table/TableModal")),
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
        config.permissions.delete = true;
    }

    return config;
};

export default getConfig;

import suppliersConfig from "./Suppliers/config";
import categoriesConfig from "./Categories/config";
import locationsConfig from "./Locations/config";
import locationGroupsConfig from "./LocationGroups/config";
import organisationsConfig from "./Organisations/config";
import translateConfig from "./Translate/config";

import { registerModule } from "moduleConfig";
import { registerProjects } from "rootProjects";
import { registerRoutings } from "rootRouting";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";
import { registerModals } from "modals";
import InventoryLocationsSelectModal from "./Permissions/modals/InventoryLocationsSelectModal";
import InventoryPermissionsModal from "./Permissions/modals/InventoryPermissionsModal";
import InventoryPermissionSaga from "./Permissions/saga";
import store from "rootStore";

export const permissionKey = "inventory";
export const url = `/inventory`;

interface IInventoryConfig {
    permissions: {
        read?: boolean;
        translation?: boolean;
    };
    routing: Array<string>;
    url: string;
}

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IInventoryConfig = {
        permissions: {
            read: false,
            translation: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    store.injectSaga?.("InventorySaga", InventoryPermissionSaga);
    registerModals({
        InventoryLocationsSelectModal,
        InventoryPermissionsModal,
    });

    if (modulePermissions.length > 0) {
        const routingChildrens = [];
        config.permissions.read = true;
        // register Locations module
        const locations = locationsConfig(modulePermissions, url);
        registerModule(`${permissionKey}.locations`, locations.config);
        routingChildrens.push(...locations.routing);

        // register Suppliers module
        const suppliers = suppliersConfig(modulePermissions, url);
        registerModule(`${permissionKey}.suppliers`, suppliers.config);
        routingChildrens.push(...suppliers.routing);

        // register Categories module
        const categories = categoriesConfig(modulePermissions, url);
        registerModule(`${permissionKey}.categories`, categories.config);
        routingChildrens.push(...categories.routing);

        // register LocationGroups module
        const locationGroups = locationGroupsConfig(modulePermissions, url);
        registerModule(
            `${permissionKey}.location_groups`,
            locationGroups.config
        );

        // register Organisations module
        const organisations = organisationsConfig(modulePermissions, url);
        registerModule(`${permissionKey}.organisations`, organisations.config);

        //register Translate module
        if (modulePermissions.indexOf(permissionKey + ".translation") !== -1) {
            config.permissions.translation = true;
            const tr = translateConfig(permissions, url);
            registerModule(`${permissionKey}.translation`, tr.config);
            routingChildrens.push(...tr.routing);
        }

        routingChildrens.push(...locationGroups.routing);

        routing.push({
            path: url + "/*",
            children: routingChildrens,
            element: <AsyncComponent path="Inventory/index" />,
        });

        registerProjects([
            {
                id: permissionKey,
                name: "Inventory",
                icon: "inventory",
                url: `${url}`,
                priority: 200,
            },
        ]);
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;

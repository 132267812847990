import { registerRoutings } from "rootRouting";
import { permissionsType, routingType } from "types/configTypes";
import { registerProjects } from "rootProjects";
import AsyncComponent from "ui/AsyncComponent";
import translateConfig from "./Translate/config";
import { registerModule } from "moduleConfig";

export const permissionKey = "online_booking";
export const url = `/online-booking`;
interface IOnlineBookingConfig {
    permissions: {
        create?: boolean;
        delete?: boolean;
        read?: boolean;
        update?: boolean;
        translation?: boolean;
    };
    routing: Array<string>;
    url: string;
}

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IOnlineBookingConfig = {
        permissions: {
            translation: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.length > 0) {
        const routingChildrens = [];
        //register Translate module
        if (modulePermissions.indexOf(permissionKey + ".translation") !== -1) {
            config.permissions.translation = true;
            const tr = translateConfig(permissions, url);
            registerModule(`${permissionKey}.translation`, tr.config);
            routingChildrens.push(...tr.routing);
        }

        routing.push({
            path: url + "/*",
            children: routingChildrens,
            element: <AsyncComponent path="OnlineBooking/index" />,
        });

        registerProjects([
            {
                id: permissionKey,
                name: "Online Booking",
                icon: "language",
                url: `${url}`,
                priority: 200,
            },
        ]);
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;

export const FETCH_SUCCESS = "BOOKING/RESTAURANT/FETCH_SUCCESS";
export const TABLE_UPDATE = "BOOKING/RESTAURANT/TABLE_UPDATE";

const types = {
    FETCH_FAILED: "BOOKING/RESTAURANT/FETCH_FAILED",
    FETCH_REQUEST: "BOOKING/RESTAURANT/FETCH_REQUEST",
    FETCH_SUCCESS,

    TABLE_UPDATE,
};

export default types;
import axios from "axios";

export const api = axios.create({
    baseURL: `${process.env.REACT_APP_DOMAIN_BASE}/api`,
    withCredentials: true,
});

export const accounts = axios.create({
    baseURL: `${process.env.REACT_APP_DOMAIN_ACCOUNTS}`,
    withCredentials: true,
});

import api from "./api";
import types from "./types";
import { AnyAction } from "redux";
import { request } from "api/apiSaga";
import { call, takeLatest } from "@redux-saga/core/effects";
import { AxiosResponse } from "axios";

function* logout(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.logout,
        action,
        "post"
    );

    if (response.status === 204) {
        window.location.href = `${process.env.REACT_APP_DOMAIN_ACCOUNTS}/login`;
    }
}

export function* me(action: AnyAction) {
    yield call(request, api.me, action);
}

export const meSagas = [
    takeLatest(types.ME_REQUEST, me),
    takeLatest(types.LOGOUT_REQUEST, logout),
];

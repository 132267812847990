export type InventoryLocation = {
    id: number;
    name: string;
};

const types = {
    ACCESS_DESTROY_FAILED: "INVENTORY_PERMISSION/ACCESS_DESTROY_FAILED",
    ACCESS_DESTROY_REQUEST: "INVENTORY_PERMISSION/ACCESS_DESTROY_REQUEST",
    ACCESS_DESTROY_SUCCESS: "INVENTORY_PERMISSION/ACCESS_DESTROY_SUCCESS",

    ACCESS_FETCH_FAILED: "INVENTORY_PERMISSION/ACCESS_FETCH_FAILED",
    ACCESS_FETCH_REQUEST: "INVENTORY_PERMISSION/ACCESS_FETCH_REQUEST",
    ACCESS_FETCH_SUCCESS: "INVENTORY_PERMISSION/ACCESS_FETCH_SUCCESS",

    ACCESS_UPDATE_FAILED: "INVENTORY_PERMISSION/ACCESS_UPDATE_FAILED",
    ACCESS_UPDATE_REQUEST: "INVENTORY_PERMISSION/ACCESS_UPDATE_REQUEST",
    ACCESS_UPDATE_SUCCESS: "INVENTORY_PERMISSION/ACCESS_UPDATE_SUCCESS",

    LOCATIONS_FETCH_FAILED: "INVENTORY_PERMISSION/LOCATIONS_FETCH_FAILED",
    LOCATIONS_FETCH_REQUEST: "INVENTORY_PERMISSION/LOCATIONS_FETCH_REQUEST",
    LOCATIONS_FETCH_SUCCESS: "INVENTORY_PERMISSION/LOCATIONS_FETCH_SUCCESS",

    LOCATIONS_ROLES_FAILED: "INVENTORY_PERMISSION/LOCATIONS_ROLES_FAILED",
    LOCATIONS_ROLES_REQUEST: "INVENTORY_PERMISSION/LOCATIONS_ROLES_REQUEST",
    LOCATIONS_ROLES_SUCCESS: "INVENTORY_PERMISSION/LOCATIONS_ROLES_SUCCESS",
};

export default types;

import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";
import types from "./types";

export const locationsFetch = (
    app: string,
    config?: AxiosRequestConfig,
    onResponse?: Function
) =>
    request(
        types.LOCATIONS_FETCH_REQUEST,
        types.LOCATIONS_FETCH_FAILED,
        types.LOCATIONS_FETCH_SUCCESS,
        "inventoryLocationsFetch",
        {
            config: { ...config, params: { _app: app, ...config?.params } },
        },
        onResponse
    );

export const locationsRoles = (
    locationId: number,
    app: string,
    config?: AxiosRequestConfig,
    onResponse?: Function
) =>
    request(
        types.LOCATIONS_ROLES_REQUEST,
        types.LOCATIONS_ROLES_FAILED,
        types.LOCATIONS_ROLES_SUCCESS,
        "inventoryLocationsRoles",
        {
            config: { ...config, params: { _app: app, ...config?.params } },
            url: { id: locationId },
        },
        onResponse
    );

export const accessFetch = (
    ssoId: number,
    app: string,
    config?: AxiosRequestConfig,
    onResponse?: Function
) =>
    request(
        types.ACCESS_FETCH_REQUEST,
        types.ACCESS_FETCH_FAILED,
        types.ACCESS_FETCH_SUCCESS,
        "inventoryAccessFetch",
        {
            config: { ...config, params: { _app: app, ...config?.params } },
            url: { id: ssoId },
        },
        onResponse
    );

export const accessUpdate = (
    ssoId: number,
    data: any,
    config?: AxiosRequestConfig,
    onResponse?: Function
) =>
    request(
        types.ACCESS_UPDATE_REQUEST,
        types.ACCESS_UPDATE_FAILED,
        types.ACCESS_UPDATE_SUCCESS,
        "inventoryAccessUpdate",
        {
            config,
            data,
            url: { id: ssoId },
        },
        onResponse
    );

export const accessDelete = (
    ssoId: number,
    app: string,
    locationId: number,
    config?: AxiosRequestConfig,
    onResponse?: Function
) =>
    request(
        types.ACCESS_DESTROY_REQUEST,
        types.ACCESS_DESTROY_FAILED,
        types.ACCESS_DESTROY_SUCCESS,
        "inventoryAccessDelete",
        {
            config: { ...config, params: { _app: app, ...config?.params } },
            url: { id: ssoId, locationId },
        },
        onResponse
    );

import { FC } from "react";
import { useSelector } from "react-redux";
import loadable from "@loadable/component";
import AppLoader from "app/App/components/AppLoader";

const App = loadable(() => import("app/App"));

const AppInit: FC = () => {
    const loaded = useSelector((state: any) => state.app.loaded);

    if (!loaded) {
        return <AppLoader />;
    }

    return <App />;
};

export default AppInit;

export const countries = {
    ad: { "alpha-2": "ad", name: "Andorra", country_code: "376" },
    ae: { "alpha-2": "ae", name: "United Arab Emirates", country_code: "971" },
    af: { "alpha-2": "af", name: "Afghanistan", country_code: "93" },
    ag: { "alpha-2": "ag", name: "Antigua", country_code: "1268" },
    ai: { "alpha-2": "ai", name: "Anguilla", country_code: "1264" },
    al: { "alpha-2": "al", name: "Albania", country_code: "355" },
    am: { "alpha-2": "am", name: "Armenia", country_code: "374" },
    ao: { "alpha-2": "ao", name: "Angola", country_code: "244" },
    ar: { "alpha-2": "ar", name: "Argentina", country_code: "54" },
    as: { "alpha-2": "as", name: "American Samoa", country_code: "1684" },
    at: { "alpha-2": "at", name: "Austria", country_code: "43" },
    au: { "alpha-2": "au", name: "Australia", country_code: "61" },
    aw: { "alpha-2": "aw", name: "Aruba", country_code: "297" },
    ax: { "alpha-2": "ax", name: "Aland Islands", country_code: "358" },
    az: { "alpha-2": "az", name: "Azerbaijan", country_code: "994" },
    ba: { "alpha-2": "ba", name: "Bosnia", country_code: "387" },
    bb: { "alpha-2": "bb", name: "Barbados", country_code: "1246" },
    bd: { "alpha-2": "bd", name: "Bangladesh", country_code: "880" },
    be: { "alpha-2": "be", name: "Belgium", country_code: "32" },
    bf: { "alpha-2": "bf", name: "Burkina Faso", country_code: "226" },
    bg: { "alpha-2": "bg", name: "Bulgaria", country_code: "359" },
    bh: { "alpha-2": "bh", name: "Bahrain", country_code: "973" },
    bi: { "alpha-2": "bi", name: "Burundi", country_code: "257" },
    bj: { "alpha-2": "bj", name: "Benin", country_code: "229" },
    bm: { "alpha-2": "bm", name: "Bermuda", country_code: "1441" },
    bn: { "alpha-2": "bn", name: "Brunei", country_code: "673" },
    bo: { "alpha-2": "bo", name: "Bolivia", country_code: "591" },
    br: { "alpha-2": "br", name: "Brazil", country_code: "55" },
    bs: { "alpha-2": "bs", name: "Bahamas", country_code: "1242" },
    bt: { "alpha-2": "bt", name: "Bhutan", country_code: "975" },
    bw: { "alpha-2": "bw", name: "Botswana", country_code: "267" },
    by: { "alpha-2": "by", name: "Belarus", country_code: "375" },
    bz: { "alpha-2": "bz", name: "Belize", country_code: "501" },
    ca: { "alpha-2": "ca", name: "Canada", country_code: "1" },
    cc: { "alpha-2": "cc", name: "Cocos Islands", country_code: "61" },
    cd: { "alpha-2": "cd", name: "Congo", country_code: "243" },
    cf: {
        "alpha-2": "cf",
        name: "Central African Republic",
        country_code: "236",
    },
    cg: { "alpha-2": "cg", name: "Congo Brazzaville", country_code: "242" },
    ch: { "alpha-2": "ch", name: "Switzerland", country_code: "41" },
    ci: { "alpha-2": "ci", name: "Cote Divoire", country_code: "225" },
    ck: { "alpha-2": "ck", name: "Cook Islands", country_code: "682" },
    cl: { "alpha-2": "cl", name: "Chile", country_code: "56" },
    cm: { "alpha-2": "cm", name: "Cameroon", country_code: "237" },
    cn: { "alpha-2": "cn", name: "China", country_code: "86" },
    co: { "alpha-2": "co", name: "Colombia", country_code: "57" },
    cr: { "alpha-2": "cr", name: "Costa Rica", country_code: "506" },
    cs: { "alpha-2": "cs", name: "Serbia", country_code: "381" },
    cu: { "alpha-2": "cu", name: "Cuba", country_code: "53" },
    cv: { "alpha-2": "cv", name: "Cape Verde", country_code: "238" },
    cx: { "alpha-2": "cx", name: "Christmas Island", country_code: "61" },
    cy: { "alpha-2": "cy", name: "Cyprus", country_code: "357" },
    cz: { "alpha-2": "cz", name: "Czech Republic", country_code: "420" },
    de: { "alpha-2": "de", name: "Germany", country_code: "49" },
    dj: { "alpha-2": "dj", name: "Djibouti", country_code: "253" },
    dk: { "alpha-2": "dk", name: "Denmark", country_code: "45" },
    dm: { "alpha-2": "dm", name: "Dominica", country_code: "1767" },
    do: { "alpha-2": "do", name: "Dominican Republic", country_code: "1849" },
    dz: { "alpha-2": "dz", name: "Algeria", country_code: "213" },
    ec: { "alpha-2": "ec", name: "Ecuador", country_code: "593" },
    ee: { "alpha-2": "ee", name: "Estonia", country_code: "372" },
    eg: { "alpha-2": "eg", name: "Egypt", country_code: "20" },
    eh: { "alpha-2": "eh", name: "Western Sahara", country_code: "212" },
    er: { "alpha-2": "er", name: "Eritrea", country_code: "291" },
    es: { "alpha-2": "es", name: "Spain", country_code: "34" },
    et: { "alpha-2": "et", name: "Ethiopia", country_code: "251" },
    fi: { "alpha-2": "fi", name: "Finland", country_code: "358" },
    fj: { "alpha-2": "fj", name: "Fiji", country_code: "679" },
    fm: { "alpha-2": "fm", name: "Micronesia", country_code: "691" },
    fo: { "alpha-2": "fo", name: "Faroe Islands", country_code: "298" },
    fr: { "alpha-2": "fr", name: "France", country_code: "33" },
    ga: { "alpha-2": "ga", name: "Gabon", country_code: "241" },
    gb: { "alpha-2": "gb", name: "England", country_code: "44" },
    gd: { "alpha-2": "gd", name: "Grenada", country_code: "1473" },
    ge: { "alpha-2": "ge", name: "Georgia", country_code: "995" },
    gf: { "alpha-2": "gf", name: "French Guiana", country_code: "594" },
    gh: { "alpha-2": "gh", name: "Ghana", country_code: "233" },
    gi: { "alpha-2": "gi", name: "Gibraltar", country_code: "350" },
    gl: { "alpha-2": "gl", name: "Greenland", country_code: "299" },
    gn: { "alpha-2": "gn", name: "Guinea", country_code: "224" },
    gp: { "alpha-2": "gp", name: "Guadeloupe", country_code: "590" },
    gq: { "alpha-2": "gq", name: "Equatorial Guinea", country_code: "240" },
    gr: { "alpha-2": "gr", name: "Greece", country_code: "30" },
    gs: { "alpha-2": "gs", name: "Sandwich Islands", country_code: "500" },
    gt: { "alpha-2": "gt", name: "Guatemala", country_code: "502" },
    gu: { "alpha-2": "gu", name: "Guam", country_code: "1671" },
    gw: { "alpha-2": "gw", name: "Guinea-Bissau", country_code: "245" },
    gy: { "alpha-2": "gy", name: "Guyana", country_code: "592" },
    hk: { "alpha-2": "hk", name: "Hong Kong", country_code: "852" },
    hn: { "alpha-2": "hn", name: "Honduras", country_code: "504" },
    hr: { "alpha-2": "hr", name: "Croatia", country_code: "385" },
    ht: { "alpha-2": "ht", name: "Haiti", country_code: "509" },
    hu: { "alpha-2": "hu", name: "Hungary", country_code: "36" },
    id: { "alpha-2": "id", name: "Indonesia", country_code: "62" },
    ie: { "alpha-2": "ie", name: "Ireland", country_code: "353" },
    il: { "alpha-2": "il", name: "Israel", country_code: "972" },
    in: { "alpha-2": "in", name: "India", country_code: "91" },
    io: {
        "alpha-2": "io",
        name: "Indian Ocean Territory",
        country_code: "246",
    },
    iq: { "alpha-2": "iq", name: "Iraq", country_code: "964" },
    ir: { "alpha-2": "ir", name: "Iran", country_code: "98" },
    is: { "alpha-2": "is", name: "Iceland", country_code: "354" },
    it: { "alpha-2": "it", name: "Italy", country_code: "39" },
    jm: { "alpha-2": "jm", name: "Jamaica", country_code: "1876" },
    jo: { "alpha-2": "jo", name: "Jordan", country_code: "962" },
    jp: { "alpha-2": "jp", name: "Japan", country_code: "81" },
    ke: { "alpha-2": "ke", name: "Kenya", country_code: "254" },
    kg: { "alpha-2": "kg", name: "Kyrgyzstan", country_code: "996" },
    kh: { "alpha-2": "kh", name: "Cambodia", country_code: "855" },
    ki: { "alpha-2": "ki", name: "Kiribati", country_code: "686" },
    km: { "alpha-2": "km", name: "Comoros", country_code: "269" },
    kn: {
        "alpha-2": "kn",
        name: "Saint Kitts and Nevis",
        country_code: "1869",
    },
    kp: { "alpha-2": "kp", name: "North Korea", country_code: "850" },
    kr: { "alpha-2": "kr", name: "South Korea", country_code: "82" },
    kw: { "alpha-2": "kw", name: "Kuwait", country_code: "965" },
    ky: { "alpha-2": "ky", name: "Cayman Islands", country_code: "1345" },
    kz: { "alpha-2": "kz", name: "Kazakhstan", country_code: "77" },
    la: { "alpha-2": "la", name: "Laos", country_code: "856" },
    lb: { "alpha-2": "lb", name: "Lebanon", country_code: "961" },
    lc: { "alpha-2": "lc", name: "Saint Lucia", country_code: "1758" },
    li: { "alpha-2": "li", name: "Liechtenstein", country_code: "423" },
    lk: { "alpha-2": "lk", name: "Sri Lanka", country_code: "94" },
    lr: { "alpha-2": "lr", name: "Liberia", country_code: "231" },
    ls: { "alpha-2": "ls", name: "Lesotho", country_code: "266" },
    lt: { "alpha-2": "lt", name: "Lithuania", country_code: "370" },
    lu: { "alpha-2": "lu", name: "Luxembourg", country_code: "352" },
    lv: { "alpha-2": "lv", name: "Latvia", country_code: "371" },
    ly: { "alpha-2": "ly", name: "Libya", country_code: "218" },
    ma: { "alpha-2": "ma", name: "Morocco", country_code: "212" },
    mc: { "alpha-2": "mc", name: "Monaco", country_code: "377" },
    md: { "alpha-2": "md", name: "Moldova", country_code: "373" },
    me: { "alpha-2": "me", name: "Montenegro", country_code: "382" },
    mg: { "alpha-2": "mg", name: "Madagascar", country_code: "261" },
    mh: { "alpha-2": "mh", name: "Marshall Islands", country_code: "692" },
    mk: { "alpha-2": "mk", name: "Macedonia", country_code: "389" },
    ml: { "alpha-2": "ml", name: "Mali", country_code: "223" },
    mn: { "alpha-2": "mn", name: "Mongolia", country_code: "976" },
    mo: { "alpha-2": "mo", name: "Macau", country_code: "853" },
    mp: {
        "alpha-2": "mp",
        name: "Northern Mariana Islands",
        country_code: "1670",
    },
    mq: { "alpha-2": "mq", name: "Martinique", country_code: "596" },
    mr: { "alpha-2": "mr", name: "Mauritania", country_code: "222" },
    ms: { "alpha-2": "ms", name: "Montserrat", country_code: "1664" },
    mt: { "alpha-2": "mt", name: "Malta", country_code: "356" },
    mu: { "alpha-2": "mu", name: "Mauritius", country_code: "230" },
    mv: { "alpha-2": "mv", name: "Maldives", country_code: "960" },
    mw: { "alpha-2": "mw", name: "Malawi", country_code: "265" },
    mx: { "alpha-2": "mx", name: "Mexico", country_code: "52" },
    my: { "alpha-2": "my", name: "Malaysia", country_code: "60" },
    mz: { "alpha-2": "mz", name: "Mozambique", country_code: "258" },
    na: { "alpha-2": "na", name: "Namibia", country_code: "264" },
    nc: { "alpha-2": "nc", name: "New Caledonia", country_code: "687" },
    ne: { "alpha-2": "ne", name: "Niger", country_code: "227" },
    nf: { "alpha-2": "nf", name: "Norfolk Island", country_code: "672" },
    ng: { "alpha-2": "ng", name: "Nigeria", country_code: "234" },
    ni: { "alpha-2": "ni", name: "Nicaragua", country_code: "505" },
    nl: { "alpha-2": "nl", name: "Netherlands", country_code: "31" },
    no: { "alpha-2": "no", name: "Norway", country_code: "47" },
    np: { "alpha-2": "np", name: "Nepal", country_code: "977" },
    nr: { "alpha-2": "nr", name: "Nauru", country_code: "674" },
    nu: { "alpha-2": "nu", name: "Niue", country_code: "683" },
    om: { "alpha-2": "om", name: "Oman", country_code: "968" },
    pa: { "alpha-2": "pa", name: "Panama", country_code: "507" },
    pe: { "alpha-2": "pe", name: "Peru", country_code: "51" },
    pf: { "alpha-2": "pf", name: "French Polynesia", country_code: "689" },
    pg: { "alpha-2": "pg", name: "New Guinea", country_code: "675" },
    ph: { "alpha-2": "ph", name: "Philippines", country_code: "63" },
    pk: { "alpha-2": "pk", name: "Pakistan", country_code: "92" },
    pl: { "alpha-2": "pl", name: "Poland", country_code: "48" },
    pm: { "alpha-2": "pm", name: "Saint Pierre", country_code: "508" },
    pn: { "alpha-2": "pn", name: "Pitcairn Islands", country_code: "64" },
    pr: { "alpha-2": "pr", name: "Puerto Rico", country_code: "1939" },
    ps: { "alpha-2": "ps", name: "Palestine", country_code: "970" },
    pt: { "alpha-2": "pt", name: "Portugal", country_code: "351" },
    pw: { "alpha-2": "pw", name: "Palau", country_code: "680" },
    py: { "alpha-2": "py", name: "Paraguay", country_code: "595" },
    qa: { "alpha-2": "qa", name: "Qatar", country_code: "974" },
    re: { "alpha-2": "re", name: "Reunion", country_code: "262" },
    ro: { "alpha-2": "ro", name: "Romania", country_code: "40" },
    rs: { "alpha-2": "rs", name: "Serbia", country_code: "381" },
    ru: { "alpha-2": "ru", name: "Russia", country_code: "7" },
    rw: { "alpha-2": "rw", name: "Rwanda", country_code: "250" },
    sa: { "alpha-2": "sa", name: "Saudi Arabia", country_code: "966" },
    sb: { "alpha-2": "sb", name: "Solomon Islands", country_code: "677" },
    sc: { "alpha-2": "sc", name: "Seychelles", country_code: "248" },
    sd: { "alpha-2": "sd", name: "Sudan", country_code: "249" },
    se: { "alpha-2": "se", name: "Sweden", country_code: "46" },
    sg: { "alpha-2": "sg", name: "Singapore", country_code: "65" },
    sh: { "alpha-2": "sh", name: "Saint Helena", country_code: "290" },
    sj: { "alpha-2": "sj", name: "Svalbard", country_code: "4779" },
    sk: { "alpha-2": "sk", name: "Slovakia", country_code: "421" },
    sl: { "alpha-2": "sl", name: "Sierra Leone", country_code: "232" },
    sm: { "alpha-2": "sm", name: "San Marino", country_code: "378" },
    sn: { "alpha-2": "sn", name: "Senegal", country_code: "221" },
    so: { "alpha-2": "so", name: "Somalia", country_code: "252" },
    sr: { "alpha-2": "sr", name: "Suriname", country_code: "597" },
    st: { "alpha-2": "st", name: "Sao Tome", country_code: "239" },
    sv: { "alpha-2": "sv", name: "El Salvador", country_code: "503" },
    sy: { "alpha-2": "sy", name: "Syria", country_code: "963" },
    sz: { "alpha-2": "sz", name: "Swaziland", country_code: "268" },
    tc: { "alpha-2": "tc", name: "Caicos Islands", country_code: "1649" },
    td: { "alpha-2": "td", name: "Chad", country_code: "235" },
    tg: { "alpha-2": "tg", name: "Togo", country_code: "228" },
    th: { "alpha-2": "th", name: "Thailand", country_code: "66" },
    tj: { "alpha-2": "tj", name: "Tajikistan", country_code: "992" },
    tk: { "alpha-2": "tk", name: "Tokelau", country_code: "690" },
    tl: { "alpha-2": "tl", name: "Timorleste", country_code: "670" },
    tm: { "alpha-2": "tm", name: "Turkmenistan", country_code: "993" },
    tn: { "alpha-2": "tn", name: "Tunisia", country_code: "216" },
    to: { "alpha-2": "to", name: "Tonga", country_code: "676" },
    tr: { "alpha-2": "tr", name: "Turkey", country_code: "90" },
    tt: { "alpha-2": "tt", name: "Trinidad", country_code: "1868" },
    tv: { "alpha-2": "tv", name: "Tuvalu", country_code: "688" },
    tw: { "alpha-2": "tw", name: "Taiwan", country_code: "886" },
    tz: { "alpha-2": "tz", name: "Tanzania", country_code: "255" },
    ua: { "alpha-2": "ua", name: "Ukraine", country_code: "380" },
    ug: { "alpha-2": "ug", name: "Uganda", country_code: "256" },
    us: { "alpha-2": "us", name: "United States", country_code: "1" },
    uy: { "alpha-2": "uy", name: "Uruguay", country_code: "598" },
    uz: { "alpha-2": "uz", name: "Uzbekistan", country_code: "998" },
    va: { "alpha-2": "va", name: "Vatican City", country_code: "379" },
    vc: { "alpha-2": "vc", name: "Saint Vincent", country_code: "1784" },
    ve: { "alpha-2": "ve", name: "Venezuela", country_code: "58" },
    vg: {
        "alpha-2": "vg",
        name: "British Virgin Islands",
        country_code: "1284",
    },
    vi: { "alpha-2": "vi", name: "Us Virgin Islands", country_code: "1340" },
    vn: { "alpha-2": "vn", name: "Vietnam", country_code: "84" },
    vu: { "alpha-2": "vu", name: "Vanuatu", country_code: "678" },
    ye: { "alpha-2": "ye", name: "Yemen", country_code: "967" },
    yt: { "alpha-2": "yt", name: "Mayotte", country_code: "262" },
    za: { "alpha-2": "za", name: "South Africa", country_code: "27" },
    zm: { "alpha-2": "zm", name: "Zambia", country_code: "260" },
    zw: { "alpha-2": "zw", name: "Zimbabwe", country_code: "263" },
};

type TCountryPhoneCodes = {
    [key: string]: string;
};
export const countryPhoneCodes: TCountryPhoneCodes = {
    "1": "us",
    "7": "ru",
    "20": "eg",
    "27": "za",
    "30": "gr",
    "31": "nl",
    "32": "be",
    "33": "fr",
    "34": "es",
    "36": "hu",
    "39": "it",
    "40": "ro",
    "41": "ch",
    "43": "at",
    "44": "gb",
    "45": "dk",
    "46": "se",
    "47": "no",
    "48": "pl",
    "49": "de",
    "51": "pe",
    "52": "mx",
    "53": "cu",
    "54": "ar",
    "55": "br",
    "56": "cl",
    "57": "co",
    "58": "ve",
    "60": "my",
    "61": "cc",
    "62": "id",
    "63": "ph",
    "64": "pn",
    "65": "sg",
    "66": "th",
    "77": "kz",
    "81": "jp",
    "82": "kr",
    "84": "vn",
    "86": "cn",
    "90": "tr",
    "91": "in",
    "92": "pk",
    "93": "af",
    "94": "lk",
    "98": "ir",
    "212": "eh",
    "213": "dz",
    "216": "tn",
    "218": "ly",
    "221": "sn",
    "222": "mr",
    "223": "ml",
    "224": "gn",
    "225": "ci",
    "226": "bf",
    "227": "ne",
    "228": "tg",
    "229": "bj",
    "230": "mu",
    "231": "lr",
    "232": "sl",
    "233": "gh",
    "234": "ng",
    "235": "td",
    "236": "cf",
    "237": "cm",
    "238": "cv",
    "239": "st",
    "240": "gq",
    "241": "ga",
    "242": "cg",
    "243": "cd",
    "244": "ao",
    "245": "gw",
    "246": "io",
    "248": "sc",
    "249": "sd",
    "250": "rw",
    "251": "et",
    "252": "so",
    "253": "dj",
    "254": "ke",
    "255": "tz",
    "256": "ug",
    "257": "bi",
    "258": "mz",
    "260": "zm",
    "261": "mg",
    "262": "re",
    "263": "zw",
    "264": "na",
    "265": "mw",
    "266": "ls",
    "267": "bw",
    "268": "sz",
    "269": "km",
    "290": "sh",
    "291": "er",
    "297": "aw",
    "298": "fo",
    "299": "gl",
    "350": "gi",
    "351": "pt",
    "352": "lu",
    "353": "ie",
    "354": "is",
    "355": "al",
    "356": "mt",
    "357": "cy",
    "358": "fi",
    "359": "bg",
    "370": "lt",
    "371": "lv",
    "372": "ee",
    "373": "md",
    "374": "am",
    "375": "by",
    "376": "ad",
    "377": "mc",
    "378": "sm",
    "379": "va",
    "380": "ua",
    "381": "rs",
    "382": "me",
    "385": "hr",
    "387": "ba",
    "389": "mk",
    "420": "cz",
    "421": "sk",
    "423": "li",
    "500": "gs",
    "501": "bz",
    "502": "gt",
    "503": "sv",
    "504": "hn",
    "505": "ni",
    "506": "cr",
    "507": "pa",
    "508": "pm",
    "509": "ht",
    "590": "gp",
    "591": "bo",
    "592": "gy",
    "593": "ec",
    "594": "gf",
    "595": "py",
    "596": "mq",
    "597": "sr",
    "598": "uy",
    "670": "tl",
    "672": "nf",
    "673": "bn",
    "674": "nr",
    "675": "pg",
    "676": "to",
    "677": "sb",
    "678": "vu",
    "679": "fj",
    "680": "pw",
    "682": "ck",
    "683": "nu",
    "686": "ki",
    "687": "nc",
    "688": "tv",
    "689": "pf",
    "690": "tk",
    "691": "fm",
    "692": "mh",
    "850": "kp",
    "852": "hk",
    "853": "mo",
    "855": "kh",
    "856": "la",
    "880": "bd",
    "886": "tw",
    "960": "mv",
    "961": "lb",
    "962": "jo",
    "963": "sy",
    "964": "iq",
    "965": "kw",
    "966": "sa",
    "967": "ye",
    "968": "om",
    "970": "ps",
    "971": "ae",
    "972": "il",
    "973": "bh",
    "974": "qa",
    "975": "bt",
    "976": "mn",
    "977": "np",
    "992": "tj",
    "993": "tm",
    "994": "az",
    "995": "ge",
    "996": "kg",
    "998": "uz",
    "1242": "bs",
    "1246": "bb",
    "1264": "ai",
    "1268": "ag",
    "1284": "vg",
    "1340": "vi",
    "1345": "ky",
    "1441": "bm",
    "1473": "gd",
    "1649": "tc",
    "1664": "ms",
    "1670": "mp",
    "1671": "gu",
    "1684": "as",
    "1758": "lc",
    "1767": "dm",
    "1784": "vc",
    "1849": "do",
    "1868": "tt",
    "1869": "kn",
    "1876": "jm",
    "1939": "pr",
    "4779": "sj",
};
export const countriesList = [
    {
        id: "+93",
        flag: "af",
        name: "Afghanistan",
        search: "+93 afghanistan",
        country: "af",
    },
    {
        id: "+358",
        flag: "ax",
        name: "Aland Islands",
        search: "+358 aland islands",
        country: "ax",
    },
    {
        id: "+355",
        flag: "al",
        name: "Albania",
        search: "+355 albania",
        country: "al",
    },
    {
        id: "+213",
        flag: "dz",
        name: "Algeria",
        search: "+213 algeria",
        country: "dz",
    },
    {
        id: "+1684",
        flag: "as",
        name: "American Samoa",
        search: "+1684 american samoa",
        country: "as",
    },
    {
        id: "+376",
        flag: "ad",
        name: "Andorra",
        search: "+376 andorra",
        country: "ad",
    },
    {
        id: "+244",
        flag: "ao",
        name: "Angola",
        search: "+244 angola",
        country: "ao",
    },
    {
        id: "+1264",
        flag: "ai",
        name: "Anguilla",
        search: "+1264 anguilla",
        country: "ai",
    },
    {
        id: "+1268",
        flag: "ag",
        name: "Antigua",
        search: "+1268 antigua",
        country: "ag",
    },
    {
        id: "+54",
        flag: "ar",
        name: "Argentina",
        search: "+54 argentina",
        country: "ar",
    },
    {
        id: "+374",
        flag: "am",
        name: "Armenia",
        search: "+374 armenia",
        country: "am",
    },
    {
        id: "+297",
        flag: "aw",
        name: "Aruba",
        search: "+297 aruba",
        country: "aw",
    },
    {
        id: "+61",
        flag: "au",
        name: "Australia",
        search: "+61 australia",
        country: "au",
    },
    {
        id: "+43",
        flag: "at",
        name: "Austria",
        search: "+43 austria",
        country: "at",
    },
    {
        id: "+994",
        flag: "az",
        name: "Azerbaijan",
        search: "+994 azerbaijan",
        country: "az",
    },
    {
        id: "+1242",
        flag: "bs",
        name: "Bahamas",
        search: "+1242 bahamas",
        country: "bs",
    },
    {
        id: "+973",
        flag: "bh",
        name: "Bahrain",
        search: "+973 bahrain",
        country: "bh",
    },
    {
        id: "+880",
        flag: "bd",
        name: "Bangladesh",
        search: "+880 bangladesh",
        country: "bd",
    },
    {
        id: "+1246",
        flag: "bb",
        name: "Barbados",
        search: "+1246 barbados",
        country: "bb",
    },
    {
        id: "+375",
        flag: "by",
        name: "Belarus",
        search: "+375 belarus",
        country: "by",
    },
    {
        id: "+32",
        flag: "be",
        name: "Belgium",
        search: "+32 belgium",
        country: "be",
    },
    {
        id: "+501",
        flag: "bz",
        name: "Belize",
        search: "+501 belize",
        country: "bz",
    },
    {
        id: "+229",
        flag: "bj",
        name: "Benin",
        search: "+229 benin",
        country: "bj",
    },
    {
        id: "+1441",
        flag: "bm",
        name: "Bermuda",
        search: "+1441 bermuda",
        country: "bm",
    },
    {
        id: "+975",
        flag: "bt",
        name: "Bhutan",
        search: "+975 bhutan",
        country: "bt",
    },
    {
        id: "+591",
        flag: "bo",
        name: "Bolivia",
        search: "+591 bolivia",
        country: "bo",
    },
    {
        id: "+387",
        flag: "ba",
        name: "Bosnia",
        search: "+387 bosnia",
        country: "ba",
    },
    {
        id: "+267",
        flag: "bw",
        name: "Botswana",
        search: "+267 botswana",
        country: "bw",
    },
    {
        id: "+55",
        flag: "br",
        name: "Brazil",
        search: "+55 brazil",
        country: "br",
    },
    {
        id: "+1284",
        flag: "vg",
        name: "British Virgin Islands",
        search: "+1284 british virgin islands",
        country: "vg",
    },
    {
        id: "+673",
        flag: "bn",
        name: "Brunei",
        search: "+673 brunei",
        country: "bn",
    },
    {
        id: "+359",
        flag: "bg",
        name: "Bulgaria",
        search: "+359 bulgaria",
        country: "bg",
    },
    {
        id: "+226",
        flag: "bf",
        name: "Burkina Faso",
        search: "+226 burkina faso",
        country: "bf",
    },
    {
        id: "+257",
        flag: "bi",
        name: "Burundi",
        search: "+257 burundi",
        country: "bi",
    },
    {
        id: "+1649",
        flag: "tc",
        name: "Caicos Islands",
        search: "+1649 caicos islands",
        country: "tc",
    },
    {
        id: "+855",
        flag: "kh",
        name: "Cambodia",
        search: "+855 cambodia",
        country: "kh",
    },
    {
        id: "+237",
        flag: "cm",
        name: "Cameroon",
        search: "+237 cameroon",
        country: "cm",
    },
    {
        id: "+1",
        flag: "ca",
        name: "Canada",
        search: "+1 canada",
        country: "ca",
    },
    {
        id: "+238",
        flag: "cv",
        name: "Cape Verde",
        search: "+238 cape verde",
        country: "cv",
    },
    {
        id: "+1345",
        flag: "ky",
        name: "Cayman Islands",
        search: "+1345 cayman islands",
        country: "ky",
    },
    {
        id: "+236",
        flag: "cf",
        name: "Central African Republic",
        search: "+236 central african republic",
        country: "cf",
    },
    {
        id: "+235",
        flag: "td",
        name: "Chad",
        search: "+235 chad",
        country: "td",
    },
    {
        id: "+56",
        flag: "cl",
        name: "Chile",
        search: "+56 chile",
        country: "cl",
    },
    {
        id: "+86",
        flag: "cn",
        name: "China",
        search: "+86 china",
        country: "cn",
    },
    {
        id: "+61",
        flag: "cx",
        name: "Christmas Island",
        search: "+61 christmas island",
        country: "cx",
    },
    {
        id: "+61",
        flag: "cc",
        name: "Cocos Islands",
        search: "+61 cocos islands",
        country: "cc",
    },
    {
        id: "+57",
        flag: "co",
        name: "Colombia",
        search: "+57 colombia",
        country: "co",
    },
    {
        id: "+269",
        flag: "km",
        name: "Comoros",
        search: "+269 comoros",
        country: "km",
    },
    {
        id: "+243",
        flag: "cd",
        name: "Congo",
        search: "+243 congo",
        country: "cd",
    },
    {
        id: "+242",
        flag: "cg",
        name: "Congo Brazzaville",
        search: "+242 congo brazzaville",
        country: "cg",
    },
    {
        id: "+682",
        flag: "ck",
        name: "Cook Islands",
        search: "+682 cook islands",
        country: "ck",
    },
    {
        id: "+506",
        flag: "cr",
        name: "Costa Rica",
        search: "+506 costa rica",
        country: "cr",
    },
    {
        id: "+225",
        flag: "ci",
        name: "Cote Divoire",
        search: "+225 cote divoire",
        country: "ci",
    },
    {
        id: "+385",
        flag: "hr",
        name: "Croatia",
        search: "+385 croatia",
        country: "hr",
    },
    { id: "+53", flag: "cu", name: "Cuba", search: "+53 cuba", country: "cu" },
    {
        id: "+357",
        flag: "cy",
        name: "Cyprus",
        search: "+357 cyprus",
        country: "cy",
    },
    {
        id: "+420",
        flag: "cz",
        name: "Czech Republic",
        search: "+420 czech republic",
        country: "cz",
    },
    {
        id: "+45",
        flag: "dk",
        name: "Denmark",
        search: "+45 denmark",
        country: "dk",
    },
    {
        id: "+253",
        flag: "dj",
        name: "Djibouti",
        search: "+253 djibouti",
        country: "dj",
    },
    {
        id: "+1767",
        flag: "dm",
        name: "Dominica",
        search: "+1767 dominica",
        country: "dm",
    },
    {
        id: "+1849",
        flag: "do",
        name: "Dominican Republic",
        search: "+1849 dominican republic",
        country: "do",
    },
    {
        id: "+593",
        flag: "ec",
        name: "Ecuador",
        search: "+593 ecuador",
        country: "ec",
    },
    {
        id: "+20",
        flag: "eg",
        name: "Egypt",
        search: "+20 egypt",
        country: "eg",
    },
    {
        id: "+503",
        flag: "sv",
        name: "El Salvador",
        search: "+503 el salvador",
        country: "sv",
    },
    {
        id: "+44",
        flag: "gb",
        name: "England",
        search: "+44 england",
        country: "gb",
    },
    {
        id: "+240",
        flag: "gq",
        name: "Equatorial Guinea",
        search: "+240 equatorial guinea",
        country: "gq",
    },
    {
        id: "+291",
        flag: "er",
        name: "Eritrea",
        search: "+291 eritrea",
        country: "er",
    },
    {
        id: "+372",
        flag: "ee",
        name: "Estonia",
        search: "+372 estonia",
        country: "ee",
    },
    {
        id: "+251",
        flag: "et",
        name: "Ethiopia",
        search: "+251 ethiopia",
        country: "et",
    },
    {
        id: "+298",
        flag: "fo",
        name: "Faroe Islands",
        search: "+298 faroe islands",
        country: "fo",
    },
    {
        id: "+679",
        flag: "fj",
        name: "Fiji",
        search: "+679 fiji",
        country: "fj",
    },
    {
        id: "+358",
        flag: "fi",
        name: "Finland",
        search: "+358 finland",
        country: "fi",
    },
    {
        id: "+33",
        flag: "fr",
        name: "France",
        search: "+33 france",
        country: "fr",
    },
    {
        id: "+594",
        flag: "gf",
        name: "French Guiana",
        search: "+594 french guiana",
        country: "gf",
    },
    {
        id: "+689",
        flag: "pf",
        name: "French Polynesia",
        search: "+689 french polynesia",
        country: "pf",
    },
    {
        id: "+241",
        flag: "ga",
        name: "Gabon",
        search: "+241 gabon",
        country: "ga",
    },
    {
        id: "+995",
        flag: "ge",
        name: "Georgia",
        search: "+995 georgia",
        country: "ge",
    },
    {
        id: "+49",
        flag: "de",
        name: "Germany",
        search: "+49 germany",
        country: "de",
    },
    {
        id: "+233",
        flag: "gh",
        name: "Ghana",
        search: "+233 ghana",
        country: "gh",
    },
    {
        id: "+350",
        flag: "gi",
        name: "Gibraltar",
        search: "+350 gibraltar",
        country: "gi",
    },
    {
        id: "+30",
        flag: "gr",
        name: "Greece",
        search: "+30 greece",
        country: "gr",
    },
    {
        id: "+299",
        flag: "gl",
        name: "Greenland",
        search: "+299 greenland",
        country: "gl",
    },
    {
        id: "+1473",
        flag: "gd",
        name: "Grenada",
        search: "+1473 grenada",
        country: "gd",
    },
    {
        id: "+590",
        flag: "gp",
        name: "Guadeloupe",
        search: "+590 guadeloupe",
        country: "gp",
    },
    {
        id: "+1671",
        flag: "gu",
        name: "Guam",
        search: "+1671 guam",
        country: "gu",
    },
    {
        id: "+502",
        flag: "gt",
        name: "Guatemala",
        search: "+502 guatemala",
        country: "gt",
    },
    {
        id: "+224",
        flag: "gn",
        name: "Guinea",
        search: "+224 guinea",
        country: "gn",
    },
    {
        id: "+245",
        flag: "gw",
        name: "Guinea-Bissau",
        search: "+245 guinea-bissau",
        country: "gw",
    },
    {
        id: "+592",
        flag: "gy",
        name: "Guyana",
        search: "+592 guyana",
        country: "gy",
    },
    {
        id: "+509",
        flag: "ht",
        name: "Haiti",
        search: "+509 haiti",
        country: "ht",
    },
    {
        id: "+504",
        flag: "hn",
        name: "Honduras",
        search: "+504 honduras",
        country: "hn",
    },
    {
        id: "+852",
        flag: "hk",
        name: "Hong Kong",
        search: "+852 hong kong",
        country: "hk",
    },
    {
        id: "+36",
        flag: "hu",
        name: "Hungary",
        search: "+36 hungary",
        country: "hu",
    },
    {
        id: "+354",
        flag: "is",
        name: "Iceland",
        search: "+354 iceland",
        country: "is",
    },
    {
        id: "+91",
        flag: "in",
        name: "India",
        search: "+91 india",
        country: "in",
    },
    {
        id: "+246",
        flag: "io",
        name: "Indian Ocean Territory",
        search: "+246 indian ocean territory",
        country: "io",
    },
    {
        id: "+62",
        flag: "id",
        name: "Indonesia",
        search: "+62 indonesia",
        country: "id",
    },
    { id: "+98", flag: "ir", name: "Iran", search: "+98 iran", country: "ir" },
    {
        id: "+964",
        flag: "iq",
        name: "Iraq",
        search: "+964 iraq",
        country: "iq",
    },
    {
        id: "+353",
        flag: "ie",
        name: "Ireland",
        search: "+353 ireland",
        country: "ie",
    },
    {
        id: "+972",
        flag: "il",
        name: "Israel",
        search: "+972 israel",
        country: "il",
    },
    {
        id: "+39",
        flag: "it",
        name: "Italy",
        search: "+39 italy",
        country: "it",
    },
    {
        id: "+1876",
        flag: "jm",
        name: "Jamaica",
        search: "+1876 jamaica",
        country: "jm",
    },
    {
        id: "+81",
        flag: "jp",
        name: "Japan",
        search: "+81 japan",
        country: "jp",
    },
    {
        id: "+962",
        flag: "jo",
        name: "Jordan",
        search: "+962 jordan",
        country: "jo",
    },
    {
        id: "+77",
        flag: "kz",
        name: "Kazakhstan",
        search: "+77 kazakhstan",
        country: "kz",
    },
    {
        id: "+254",
        flag: "ke",
        name: "Kenya",
        search: "+254 kenya",
        country: "ke",
    },
    {
        id: "+686",
        flag: "ki",
        name: "Kiribati",
        search: "+686 kiribati",
        country: "ki",
    },
    {
        id: "+965",
        flag: "kw",
        name: "Kuwait",
        search: "+965 kuwait",
        country: "kw",
    },
    {
        id: "+996",
        flag: "kg",
        name: "Kyrgyzstan",
        search: "+996 kyrgyzstan",
        country: "kg",
    },
    {
        id: "+856",
        flag: "la",
        name: "Laos",
        search: "+856 laos",
        country: "la",
    },
    {
        id: "+371",
        flag: "lv",
        name: "Latvia",
        search: "+371 latvia",
        country: "lv",
    },
    {
        id: "+961",
        flag: "lb",
        name: "Lebanon",
        search: "+961 lebanon",
        country: "lb",
    },
    {
        id: "+266",
        flag: "ls",
        name: "Lesotho",
        search: "+266 lesotho",
        country: "ls",
    },
    {
        id: "+231",
        flag: "lr",
        name: "Liberia",
        search: "+231 liberia",
        country: "lr",
    },
    {
        id: "+218",
        flag: "ly",
        name: "Libya",
        search: "+218 libya",
        country: "ly",
    },
    {
        id: "+423",
        flag: "li",
        name: "Liechtenstein",
        search: "+423 liechtenstein",
        country: "li",
    },
    {
        id: "+370",
        flag: "lt",
        name: "Lithuania",
        search: "+370 lithuania",
        country: "lt",
    },
    {
        id: "+352",
        flag: "lu",
        name: "Luxembourg",
        search: "+352 luxembourg",
        country: "lu",
    },
    {
        id: "+853",
        flag: "mo",
        name: "Macau",
        search: "+853 macau",
        country: "mo",
    },
    {
        id: "+389",
        flag: "mk",
        name: "Macedonia",
        search: "+389 macedonia",
        country: "mk",
    },
    {
        id: "+261",
        flag: "mg",
        name: "Madagascar",
        search: "+261 madagascar",
        country: "mg",
    },
    {
        id: "+265",
        flag: "mw",
        name: "Malawi",
        search: "+265 malawi",
        country: "mw",
    },
    {
        id: "+60",
        flag: "my",
        name: "Malaysia",
        search: "+60 malaysia",
        country: "my",
    },
    {
        id: "+960",
        flag: "mv",
        name: "Maldives",
        search: "+960 maldives",
        country: "mv",
    },
    {
        id: "+223",
        flag: "ml",
        name: "Mali",
        search: "+223 mali",
        country: "ml",
    },
    {
        id: "+356",
        flag: "mt",
        name: "Malta",
        search: "+356 malta",
        country: "mt",
    },
    {
        id: "+692",
        flag: "mh",
        name: "Marshall Islands",
        search: "+692 marshall islands",
        country: "mh",
    },
    {
        id: "+596",
        flag: "mq",
        name: "Martinique",
        search: "+596 martinique",
        country: "mq",
    },
    {
        id: "+222",
        flag: "mr",
        name: "Mauritania",
        search: "+222 mauritania",
        country: "mr",
    },
    {
        id: "+230",
        flag: "mu",
        name: "Mauritius",
        search: "+230 mauritius",
        country: "mu",
    },
    {
        id: "+262",
        flag: "yt",
        name: "Mayotte",
        search: "+262 mayotte",
        country: "yt",
    },
    {
        id: "+52",
        flag: "mx",
        name: "Mexico",
        search: "+52 mexico",
        country: "mx",
    },
    {
        id: "+691",
        flag: "fm",
        name: "Micronesia",
        search: "+691 micronesia",
        country: "fm",
    },
    {
        id: "+373",
        flag: "md",
        name: "Moldova",
        search: "+373 moldova",
        country: "md",
    },
    {
        id: "+377",
        flag: "mc",
        name: "Monaco",
        search: "+377 monaco",
        country: "mc",
    },
    {
        id: "+976",
        flag: "mn",
        name: "Mongolia",
        search: "+976 mongolia",
        country: "mn",
    },
    {
        id: "+382",
        flag: "me",
        name: "Montenegro",
        search: "+382 montenegro",
        country: "me",
    },
    {
        id: "+1664",
        flag: "ms",
        name: "Montserrat",
        search: "+1664 montserrat",
        country: "ms",
    },
    {
        id: "+212",
        flag: "ma",
        name: "Morocco",
        search: "+212 morocco",
        country: "ma",
    },
    {
        id: "+258",
        flag: "mz",
        name: "Mozambique",
        search: "+258 mozambique",
        country: "mz",
    },
    {
        id: "+264",
        flag: "na",
        name: "Namibia",
        search: "+264 namibia",
        country: "na",
    },
    {
        id: "+674",
        flag: "nr",
        name: "Nauru",
        search: "+674 nauru",
        country: "nr",
    },
    {
        id: "+977",
        flag: "np",
        name: "Nepal",
        search: "+977 nepal",
        country: "np",
    },
    {
        id: "+31",
        flag: "nl",
        name: "Netherlands",
        search: "+31 netherlands",
        country: "nl",
    },
    {
        id: "+687",
        flag: "nc",
        name: "New Caledonia",
        search: "+687 new caledonia",
        country: "nc",
    },
    {
        id: "+675",
        flag: "pg",
        name: "New Guinea",
        search: "+675 new guinea",
        country: "pg",
    },
    {
        id: "+505",
        flag: "ni",
        name: "Nicaragua",
        search: "+505 nicaragua",
        country: "ni",
    },
    {
        id: "+227",
        flag: "ne",
        name: "Niger",
        search: "+227 niger",
        country: "ne",
    },
    {
        id: "+234",
        flag: "ng",
        name: "Nigeria",
        search: "+234 nigeria",
        country: "ng",
    },
    {
        id: "+683",
        flag: "nu",
        name: "Niue",
        search: "+683 niue",
        country: "nu",
    },
    {
        id: "+672",
        flag: "nf",
        name: "Norfolk Island",
        search: "+672 norfolk island",
        country: "nf",
    },
    {
        id: "+850",
        flag: "kp",
        name: "North Korea",
        search: "+850 north korea",
        country: "kp",
    },
    {
        id: "+1670",
        flag: "mp",
        name: "Northern Mariana Islands",
        search: "+1670 northern mariana islands",
        country: "mp",
    },
    {
        id: "+47",
        flag: "no",
        name: "Norway",
        search: "+47 norway",
        country: "no",
    },
    {
        id: "+968",
        flag: "om",
        name: "Oman",
        search: "+968 oman",
        country: "om",
    },
    {
        id: "+92",
        flag: "pk",
        name: "Pakistan",
        search: "+92 pakistan",
        country: "pk",
    },
    {
        id: "+680",
        flag: "pw",
        name: "Palau",
        search: "+680 palau",
        country: "pw",
    },
    {
        id: "+970",
        flag: "ps",
        name: "Palestine",
        search: "+970 palestine",
        country: "ps",
    },
    {
        id: "+507",
        flag: "pa",
        name: "Panama",
        search: "+507 panama",
        country: "pa",
    },
    {
        id: "+595",
        flag: "py",
        name: "Paraguay",
        search: "+595 paraguay",
        country: "py",
    },
    { id: "+51", flag: "pe", name: "Peru", search: "+51 peru", country: "pe" },
    {
        id: "+63",
        flag: "ph",
        name: "Philippines",
        search: "+63 philippines",
        country: "ph",
    },
    {
        id: "+64",
        flag: "pn",
        name: "Pitcairn Islands",
        search: "+64 pitcairn islands",
        country: "pn",
    },
    {
        id: "+48",
        flag: "pl",
        name: "Poland",
        search: "+48 poland",
        country: "pl",
    },
    {
        id: "+351",
        flag: "pt",
        name: "Portugal",
        search: "+351 portugal",
        country: "pt",
    },
    {
        id: "+1939",
        flag: "pr",
        name: "Puerto Rico",
        search: "+1939 puerto rico",
        country: "pr",
    },
    {
        id: "+974",
        flag: "qa",
        name: "Qatar",
        search: "+974 qatar",
        country: "qa",
    },
    {
        id: "+262",
        flag: "re",
        name: "Reunion",
        search: "+262 reunion",
        country: "re",
    },
    {
        id: "+40",
        flag: "ro",
        name: "Romania",
        search: "+40 romania",
        country: "ro",
    },
    {
        id: "+7",
        flag: "ru",
        name: "Russia",
        search: "+7 russia",
        country: "ru",
    },
    {
        id: "+250",
        flag: "rw",
        name: "Rwanda",
        search: "+250 rwanda",
        country: "rw",
    },
    {
        id: "+290",
        flag: "sh",
        name: "Saint Helena",
        search: "+290 saint helena",
        country: "sh",
    },
    {
        id: "+1869",
        flag: "kn",
        name: "Saint Kitts and Nevis",
        search: "+1869 saint kitts and nevis",
        country: "kn",
    },
    {
        id: "+1758",
        flag: "lc",
        name: "Saint Lucia",
        search: "+1758 saint lucia",
        country: "lc",
    },
    {
        id: "+508",
        flag: "pm",
        name: "Saint Pierre",
        search: "+508 saint pierre",
        country: "pm",
    },
    {
        id: "+1784",
        flag: "vc",
        name: "Saint Vincent",
        search: "+1784 saint vincent",
        country: "vc",
    },
    {
        id: "+378",
        flag: "sm",
        name: "San Marino",
        search: "+378 san marino",
        country: "sm",
    },
    {
        id: "+500",
        flag: "gs",
        name: "Sandwich Islands",
        search: "+500 sandwich islands",
        country: "gs",
    },
    {
        id: "+239",
        flag: "st",
        name: "Sao Tome",
        search: "+239 sao tome",
        country: "st",
    },
    {
        id: "+966",
        flag: "sa",
        name: "Saudi Arabia",
        search: "+966 saudi arabia",
        country: "sa",
    },
    {
        id: "+221",
        flag: "sn",
        name: "Senegal",
        search: "+221 senegal",
        country: "sn",
    },
    {
        id: "+381",
        flag: "rs",
        name: "Serbia",
        search: "+381 serbia",
        country: "rs",
    },
    {
        id: "+248",
        flag: "sc",
        name: "Seychelles",
        search: "+248 seychelles",
        country: "sc",
    },
    {
        id: "+232",
        flag: "sl",
        name: "Sierra Leone",
        search: "+232 sierra leone",
        country: "sl",
    },
    {
        id: "+65",
        flag: "sg",
        name: "Singapore",
        search: "+65 singapore",
        country: "sg",
    },
    {
        id: "+421",
        flag: "sk",
        name: "Slovakia",
        search: "+421 slovakia",
        country: "sk",
    },
    {
        id: "+677",
        flag: "sb",
        name: "Solomon Islands",
        search: "+677 solomon islands",
        country: "sb",
    },
    {
        id: "+252",
        flag: "so",
        name: "Somalia",
        search: "+252 somalia",
        country: "so",
    },
    {
        id: "+27",
        flag: "za",
        name: "South Africa",
        search: "+27 south africa",
        country: "za",
    },
    {
        id: "+82",
        flag: "kr",
        name: "South Korea",
        search: "+82 south korea",
        country: "kr",
    },
    {
        id: "+34",
        flag: "es",
        name: "Spain",
        search: "+34 spain",
        country: "es",
    },
    {
        id: "+94",
        flag: "lk",
        name: "Sri Lanka",
        search: "+94 sri lanka",
        country: "lk",
    },
    {
        id: "+249",
        flag: "sd",
        name: "Sudan",
        search: "+249 sudan",
        country: "sd",
    },
    {
        id: "+597",
        flag: "sr",
        name: "Suriname",
        search: "+597 suriname",
        country: "sr",
    },
    {
        id: "+4779",
        flag: "sj",
        name: "Svalbard",
        search: "+4779 svalbard",
        country: "sj",
    },
    {
        id: "+268",
        flag: "sz",
        name: "Swaziland",
        search: "+268 swaziland",
        country: "sz",
    },
    {
        id: "+46",
        flag: "se",
        name: "Sweden",
        search: "+46 sweden",
        country: "se",
    },
    {
        id: "+41",
        flag: "ch",
        name: "Switzerland",
        search: "+41 switzerland",
        country: "ch",
    },
    {
        id: "+963",
        flag: "sy",
        name: "Syria",
        search: "+963 syria",
        country: "sy",
    },
    {
        id: "+886",
        flag: "tw",
        name: "Taiwan",
        search: "+886 taiwan",
        country: "tw",
    },
    {
        id: "+992",
        flag: "tj",
        name: "Tajikistan",
        search: "+992 tajikistan",
        country: "tj",
    },
    {
        id: "+255",
        flag: "tz",
        name: "Tanzania",
        search: "+255 tanzania",
        country: "tz",
    },
    {
        id: "+66",
        flag: "th",
        name: "Thailand",
        search: "+66 thailand",
        country: "th",
    },
    {
        id: "+670",
        flag: "tl",
        name: "Timorleste",
        search: "+670 timorleste",
        country: "tl",
    },
    {
        id: "+228",
        flag: "tg",
        name: "Togo",
        search: "+228 togo",
        country: "tg",
    },
    {
        id: "+690",
        flag: "tk",
        name: "Tokelau",
        search: "+690 tokelau",
        country: "tk",
    },
    {
        id: "+676",
        flag: "to",
        name: "Tonga",
        search: "+676 tonga",
        country: "to",
    },
    {
        id: "+1868",
        flag: "tt",
        name: "Trinidad",
        search: "+1868 trinidad",
        country: "tt",
    },
    {
        id: "+216",
        flag: "tn",
        name: "Tunisia",
        search: "+216 tunisia",
        country: "tn",
    },
    {
        id: "+90",
        flag: "tr",
        name: "Turkey",
        search: "+90 turkey",
        country: "tr",
    },
    {
        id: "+993",
        flag: "tm",
        name: "Turkmenistan",
        search: "+993 turkmenistan",
        country: "tm",
    },
    {
        id: "+688",
        flag: "tv",
        name: "Tuvalu",
        search: "+688 tuvalu",
        country: "tv",
    },
    {
        id: "+256",
        flag: "ug",
        name: "Uganda",
        search: "+256 uganda",
        country: "ug",
    },
    {
        id: "+380",
        flag: "ua",
        name: "Ukraine",
        search: "+380 ukraine",
        country: "ua",
    },
    {
        id: "+971",
        flag: "ae",
        name: "United Arab Emirates",
        search: "+971 united arab emirates",
        country: "ae",
    },
    {
        id: "+1",
        flag: "us",
        name: "United States",
        search: "+1 united states",
        country: "us",
    },
    {
        id: "+598",
        flag: "uy",
        name: "Uruguay",
        search: "+598 uruguay",
        country: "uy",
    },
    {
        id: "+1340",
        flag: "vi",
        name: "Us Virgin Islands",
        search: "+1340 us virgin islands",
        country: "vi",
    },
    {
        id: "+998",
        flag: "uz",
        name: "Uzbekistan",
        search: "+998 uzbekistan",
        country: "uz",
    },
    {
        id: "+678",
        flag: "vu",
        name: "Vanuatu",
        search: "+678 vanuatu",
        country: "vu",
    },
    {
        id: "+379",
        flag: "va",
        name: "Vatican City",
        search: "+379 vatican city",
        country: "va",
    },
    {
        id: "+58",
        flag: "ve",
        name: "Venezuela",
        search: "+58 venezuela",
        country: "ve",
    },
    {
        id: "+84",
        flag: "vn",
        name: "Vietnam",
        search: "+84 vietnam",
        country: "vn",
    },
    {
        id: "+212",
        flag: "eh",
        name: "Western Sahara",
        search: "+212 western sahara",
        country: "eh",
    },
    {
        id: "+967",
        flag: "ye",
        name: "Yemen",
        search: "+967 yemen",
        country: "ye",
    },
    {
        id: "+260",
        flag: "zm",
        name: "Zambia",
        search: "+260 zambia",
        country: "zm",
    },
    {
        id: "+263",
        flag: "zw",
        name: "Zimbabwe",
        search: "+263 zimbabwe",
        country: "zw",
    },
];

// export const getCountryCode = country => {
// 	if(countries[country] !== undefined) {
// 		return '+'+countries[country].country_code;
// 	}

// 	return window.fallback_phone_country_code;
// }

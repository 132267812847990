import api from "api/apiReducer";
import app from "app/App/reducer";
import me from "app/Me/reducer";

const exportObject = {
    api,
    app,
    me,
};

export default exportObject;

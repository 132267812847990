import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";
import types from "./types";
import { ISellers } from "./interfaces";

export const sellersCreate = (
    data: ISellers,
    onResponse?: Function
) =>
    request(
        types.STORE_REQUEST,
        types.STORE_FAILED,
        types.STORE_SUCCESS,
        "sellersCreate",
        { data },
        onResponse
    );

export const sellersFetch = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        "sellersFetch",
        {
            config,
        }
    );

    export const sellersFetchAll = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_ALL_REQUEST,
        types.FETCH_ALL_FAILED,
        types.FETCH_ALL_SUCCESS,
        "sellersFetchAll",
        {
            config,
        }
    );

export const sellersDelete = (id: number, onResponse?: Function) =>
    request(
        types.DESTROY_REQUEST,
        types.DESTROY_FAILED,
        types.DESTROY_SUCCESS,
        "sellersDelete",
        {
            url: {
                id,
            },
        },
        onResponse
    );

export const sellersGet = (id: number) =>
    request(
        types.GET_REQUEST,
        types.GET_FAILED,
        types.GET_SUCCESS,
        "sellersGet",
        {
            url: {
                id,
            },
        }
    );

export const sellersUpdate = (
    id: number,
    data: ISellers,
    onResponse?: Function
) => {
    return request(
        types.UPDATE_REQUEST,
        types.UPDATE_FAILED,
        types.UPDATE_SUCCESS,
        "sellersUpdate",
        { data, url: { id } },
        onResponse
    );
};

export const sellersTableUpdate = (action: string, data: any) => ({
    type: types.TABLE_UPDATE,
    action,
    data,
});

import {
    Box,
    Icon,
    IconButton,
    Stack,
    Typography,
    BoxProps,
} from "@mui/material";
import React from "react";

interface IGPModalTitle extends BoxProps {
    onClose: (event: React.MouseEvent) => void;
}

function GPModalTitle(props: IGPModalTitle) {
    const { children, onClose, ...restProps } = props;

    return (
        <Box {...restProps}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h6">{children}</Typography>

                {!!onClose && (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            alignSelf: "start",
                        }}
                    >
                        <Icon>close</Icon>
                    </IconButton>
                )}
            </Stack>
        </Box>
    );
}

GPModalTitle.defaultProps = {
    sx: {
        pl: 2,
        pt: 1,
        pr: 1,
    },
};

export default GPModalTitle;

export type EventLocation = {
    id: number;
    name: string;
};

export const TABLE_UPDATE = "EVENTS/TABLE_UPDATE";

const types = {
    ACCESS_DESTROY_FAILED: "EVENTS/ACCESS_DESTROY_FAILED",
    ACCESS_DESTROY_REQUEST: "EVENTS/ACCESS_DESTROY_REQUEST",
    ACCESS_DESTROY_SUCCESS: "EVENTS/ACCESS_DESTROY_SUCCESS",

    ACCESS_FETCH_FAILED: "EVENTS/ACCESS_FETCH_FAILED",
    ACCESS_FETCH_REQUEST: "EVENTS/ACCESS_FETCH_REQUEST",
    ACCESS_FETCH_SUCCESS: "EVENTS/ACCESS_FETCH_SUCCESS",

    ACCESS_UPDATE_FAILED: "EVENTS/ACCESS_UPDATE_FAILED",
    ACCESS_UPDATE_REQUEST: "EVENTS/ACCESS_UPDATE_REQUEST",
    ACCESS_UPDATE_SUCCESS: "EVENTS/ACCESS_UPDATE_SUCCESS",

    LOCATIONS_FETCH_FAILED: "EVENTS/LOCATIONS_FETCH_FAILED",
    LOCATIONS_FETCH_REQUEST: "EVENTS/LOCATIONS_FETCH_REQUEST",
    LOCATIONS_FETCH_SUCCESS: "EVENTS/LOCATIONS_FETCH_SUCCESS",

    LOCATIONS_ROLES_FAILED: "EVENTS/LOCATIONS_ROLES_FAILED",
    LOCATIONS_ROLES_REQUEST: "EVENTS/LOCATIONS_ROLES_REQUEST",
    LOCATIONS_ROLES_SUCCESS: "EVENTS/LOCATIONS_ROLES_SUCCESS",

    TABLE_UPDATE,
};

export default types;

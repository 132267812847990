export const TABLE_UPDATE = "EVENT_LOCATION_GROUPS/TABLE_UPDATE";
export const FETCH_SUCCESS = "EVENT_LOCATION_GROUPS/FETCH_SUCCESS";

const types = {
    DESTROY_FAILED: "EVENT_LOCATION_GROUPS/DESTROY_FAILED",
    DESTROY_REQUEST: "EVENT_LOCATION_GROUPS/DESTROY_REQUEST",
    DESTROY_SUCCESS: "EVENT_LOCATION_GROUPS/DESTROY_SUCCESS",

    FETCH_FAILED: "EVENT_LOCATION_GROUPS/FETCH_FAILED",
    FETCH_REQUEST: "EVENT_LOCATION_GROUPS/FETCH_REQUEST",
    FETCH_SUCCESS,

    FETCH_ALL_FAILED: "EVENT_LOCATION_GROUPS/FETCH_ALL_FAILED",
    FETCH_ALL_REQUEST: "EVENT_LOCATION_GROUPS/FETCH_ALL_REQUEST",
    FETCH_ALL_SUCCESS: "EVENT_LOCATION_GROUPS/FETCH_ALL_SUCCESS",

    GET_FAILED: "EVENT_LOCATION_GROUPS/GET_FAILED",
    GET_REQUEST: "EVENT_LOCATION_GROUPS/GET_REQUEST",
    GET_SUCCESS: "EVENT_LOCATION_GROUPS/GET_SUCCESS",

    PATCH_FAILED: "EVENT_LOCATION_GROUPS/PATCH_FAILED",
    PATCH_REQUEST: "EVENT_LOCATION_GROUPS/PATCH_REQUEST",
    PATCH_SUCCESS: "EVENT_LOCATION_GROUPS/PATCH_SUCCESS",
    
    STORE_FAILED: "EVENT_LOCATION_GROUPS/STORE_FAILED",
    STORE_REQUEST: "EVENT_LOCATION_GROUPS/STORE_REQUEST",
    STORE_SUCCESS: "EVENT_LOCATION_GROUPS/STORE_SUCCESS",

    UPDATE_FAILED: "EVENT_LOCATION_GROUPS/UPDATE_FAILED",
    UPDATE_REQUEST: "EVENT_LOCATION_GROUPS/UPDATE_REQUEST",
    UPDATE_SUCCESS: "EVENT_LOCATION_GROUPS/UPDATE_SUCCESS",
    TABLE_UPDATE,
};

export default types;

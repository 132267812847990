import { FC, useRef } from "react";
import { Box } from "@mui/material";
import { Modal, ModalTitle, ModalContent, ModalActions, Button } from "ui";
import { useDispatch } from "react-redux";
import { modalHide } from "app/App/actions";
import SimplePermissionsForm from "../forms/SimplePermissionsForm";

type TSimplePermissionsModal = {
    applicationKey: string;
    userId: number;
    onClose?: Function;
};

const SimplePermissionsModal: FC<TSimplePermissionsModal> = ({
    applicationKey,
    onClose,
    userId,
}) => {
    const dispatch = useDispatch();
    const formRef = useRef<any>();

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
        dispatch(modalHide("SimplePermissionsModal"));
    };

    const handleSaveForm = (
        event: React.FormEvent<HTMLFormElement | HTMLButtonElement>
    ) => {
        formRef.current?.onSubmit(event);
    };

    return (
        <Modal open maxWidth="md" fullWidth onClose={handleClose}>
            <ModalTitle onClose={handleClose}></ModalTitle>

            <ModalContent sx={{ p: 2 }}>
                <SimplePermissionsForm
                    applicationKey={applicationKey}
                    userId={userId}
                    ref={formRef}
                />
            </ModalContent>
            <ModalActions>
                <Box display="flex" width="100%" textAlign="right">
                    <Button
                        onClick={handleClose}
                        color="inherit"
                        variant="text"
                        sx={{ mr: 1 }}
                    >
                        Cancel
                    </Button>
                    <Button sx={{ flex: 1 }} onClick={handleSaveForm}>
                        Save
                    </Button>
                </Box>
            </ModalActions>
        </Modal>
    );
};

export default SimplePermissionsModal;

import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const requests = {
    destroy: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.delete(`/inventory/organisations/${url.id}`, config),
    fetch: (config: AxiosRequestConfig) => api.get(`/inventory/organisations`, config),
    fetchAll: (config: AxiosRequestConfig) => api.get(`/inventory/organisations/all`, config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/inventory/organisations/${url.id}`, config),
    store: (data: object, config: AxiosRequestConfig) =>
        api.post(`/inventory/organisations`, data, config),
    update: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`/inventory/organisations/${url.id}`, data, config),
    patch: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.patch(`/inventory/organisations/${url.id}`, data, config),
};

export default requests;

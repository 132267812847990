import OrganisationsSaga from "./saga"

import IConfig from "interfaces/IConfig";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";

export const permissionKey = "inventory.organisations";
export const url = "locations";
export const organisationsUrl = `${url}/organsations`;

const getConfig = (permissions: permissionsType, parentUrl: string) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            create: false,
            delete: false,
            read: false,
            update: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        // store.reducerManager?.add("InventoryLocationTable", OrganisationsTableReducer);
        store.injectSaga?.("OrganisationsSaga", OrganisationsSaga);
        // routing.push({
        //     path: url,
        //     element: (
        //         <AsyncComponent path="Inventory/Organisations/pages/OrganisationsIndexPage" />
        //     ),
        // });
    }

    // if (modulePermissions.indexOf(permissionKey + ".create") !== -1) {
    //     config.permissions.create = true;

    //     routing.push({
    //         path: `${url}/create`,
    //         element: (
    //             <AsyncComponent path="Inventory/Organisations/pages/OrganisationsCreatePage" />
    //         ),
    //     });
    // }
    // if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
    //     config.permissions.update = true;
    //     routing.push({
    //         path: `${url}/:id/edit`,
    //         element: (
    //             <AsyncComponent path="Inventory/Organisations/pages/OrganisationsEditPage" />
    //         ),
    //     });
    // }


    if (routing.length > 0) {
        config.routing = routing.map((item) => parentUrl + "/" + item.path);
    }

    return { config, routing };
};

export default getConfig;

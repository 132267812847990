import { Box, Icon, Paper, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalContent, ModalTitle } from "ui";
import { modalHide } from "../actions";
import { getProjects } from "rootProjects";
import IProject from "interfaces/IProject";
import { useNavigate } from "react-router-dom";
import { grey } from "@mui/material/colors";

const projects = getProjects() as IProject[];

const ProjectsModal: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChangeProject = (url: string) => () => {
        navigate(url);
        dispatch(modalHide("ProjectsModal"));
    };

    const handleClose = () => {
        dispatch(modalHide("ProjectsModal"));
    };

    const handleToggleFavorite = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    const renderProject = (project: IProject) => {
        return (
            <Box
                key={project.id}
                sx={{
                    cursor: "pointer",
                    width: 150,
                    "&:hover": {
                        "& .project-paper": {
                            backgroundColor: grey[100],
                        },
                    },
                }}
                onClick={handleChangeProject(project.url)}
            >
                <Paper
                    className="project-paper"
                    sx={{
                        alignItems: "center",
                        display: "flex",
                        height: 150,
                        justifyContent: "center",
                        position: "relative",
                    }}
                >
                    <Icon sx={{ fontSize: 50 }}>{project.icon}</Icon>
                    <Icon
                        onClick={handleToggleFavorite}
                        sx={(theme) => ({
                            color: 1
                                ? theme.palette.warning.light
                                : theme.palette.gray.light,
                            position: "absolute",
                            top: 8,
                            right: 8,
                            "&:hover": {
                                color: 1
                                    ? theme.palette.gray.light
                                    : theme.palette.warning.light,
                            },
                        })}
                    >
                        star
                    </Icon>
                </Paper>
                <Typography
                    align="center"
                    fontSize={18}
                    fontWeight={500}
                    sx={{
                        overflow: "hidden",
                        pt: 1,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                >
                    {project.name}
                </Typography>
            </Box>
        );
    };

    return (
        <Modal maxWidth="lg" fullWidth onClose={handleClose} open>
            <ModalTitle onClose={handleClose}>Projects</ModalTitle>

            <ModalContent>
                <Stack
                    spacing={5}
                    direction="row"
                    flexWrap="wrap"
                    justifyContent="center"
                >
                    {projects.map((item) => renderProject(item))}
                </Stack>
            </ModalContent>
        </Modal>
    );
};

export default ProjectsModal;

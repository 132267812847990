export const FETCH_FAILED = "POTENTIAL_CLIENTS/FETCH_FAILED";
export const FETCH_REQUEST = "POTENTIAL_CLIENTS/FETCH_REQUEST";
export const FETCH_SUCCESS = "POTENTIAL_CLIENTS/FETCH_SUCCESS";

export const TABLE_UPDATE = "POTENTIAL_CLIENTS/TABLE_UPDATE";

const types = {
    DESTROY_FAILED: "POTENTIAL_CLIENTS/DESTROY_FAILED",
    DESTROY_REQUEST: "POTENTIAL_CLIENTS/DESTROY_REQUEST",
    DESTROY_SUCCESS: "POTENTIAL_CLIENTS/DESTROY_SUCCESS",

    FETCH_FAILED,
    FETCH_REQUEST,
    FETCH_SUCCESS,

    GET_FAILED: "POTENTIAL_CLIENTS/GET_FAILED",
    GET_REQUEST: "POTENTIAL_CLIENTS/GET_REQUEST",
    GET_SUCCESS: "POTENTIAL_CLIENTS/GET_SUCCESS",

    PATCH_FAILED: "POTENTIAL_CLIENTS/PATCH_FAILED",
    PATCH_REQUEST: "POTENTIAL_CLIENTS/PATCH_REQUEST",
    PATCH_SUCCESS: "POTENTIAL_CLIENTS/PATCH_SUCCESS",

    STORE_FAILED: "POTENTIAL_CLIENTS/STORE_FAILED",
    STORE_REQUEST: "POTENTIAL_CLIENTS/STORE_REQUEST",
    STORE_SUCCESS: "POTENTIAL_CLIENTS/STORE_SUCCESS",

    UPDATE_FAILED: "POTENTIAL_CLIENTS/UPDATE_FAILED",
    UPDATE_REQUEST: "POTENTIAL_CLIENTS/UPDATE_REQUEST",
    UPDATE_SUCCESS: "POTENTIAL_CLIENTS/UPDATE_SUCCESS",

    GET_LETTER_FAILED: "POTENTIAL_CLIENTS_LETTER/GET_FAILED",
    GET_LETTER_REQUEST: "POTENTIAL_CLIENTS_LETTER/GET_REQUEST",
    GET_LETTER_SUCCESS: "POTENTIAL_CLIENTS_LETTER/GET_SUCCESS",

    BULK_LETTERS_FAILED: "POTENTIAL_CLIENTS_LETTERS/BULK_FAILED",
    BULK_LETTERS_REQUEST: "POTENTIAL_CLIENTS_LETTERS/BULK_REQUEST",
    BULK_LETTERS_SUCCESS: "POTENTIAL_CLIENTS_LETTERS/BULK_SUCCESS",

    BULK_STATUS_FAILED: "POTENTIAL_CLIENTS_STATUS/BULK_FAILED",
    BULK_STATUS_REQUEST: "POTENTIAL_CLIENTS_STATUS/BULK_REQUEST",
    BULK_STATUS_SUCCESS: "POTENTIAL_CLIENTS_STATUS/BULK_SUCCESS",

    BULK_SELLER_FAILED: "POTENTIAL_CLIENTS_SELLER/BULK_FAILED",
    BULK_SELLER_REQUEST: "POTENTIAL_CLIENTS_SELLER/BULK_REQUEST",
    BULK_SELLER_SUCCESS: "POTENTIAL_CLIENTS_SELLER/BULK_SUCCESS",

    TABLE_UPDATE,
};

export default types;

import { FC, useContext, useCallback, ReactNode } from "react";
import FormContext from "./FormContext";
import "@simonwep/pickr/dist/themes/nano.min.css";
import TextField from "./TextField";
import { IGPTextField } from "./TextField";
import ColorPicker from "ui/ColorPicker/ColorPicker";
import { getValue } from "./helpers";

interface IGPColorPicker extends IGPTextField {
    id: string;
    errorText?: string;
    onChange?: Function;
    value?: string;
    defaultColor?: string;
    renderendadorment?: ReactNode;
}

const GPColorPicker: FC<IGPColorPicker> = (props) => {
    const { id, onChange, value, defaultColor, ...restProps } = props;

    const context = useContext(FormContext);
    const color = getValue(id, context, value);

    const handleChange = useCallback(
        (updatedColor: string) => {
            let newColor = updatedColor;

            if (updatedColor?.length === 0) {
                newColor = "#";
            }

            if (newColor?.[0] !== "#" && updatedColor?.length === 1) {
                newColor = "#" + updatedColor;
            }

            if (!!onChange) {
                onChange({ id, value: newColor }, context);
            } else {
                context?.onChange({ id, value: newColor });
            }
        },
        [context, id, onChange]
    );

    const handleChangeInputValue = ({ value }: { value: string }) => {
        handleChange(value);
    };

    return (
        <TextField
            {...restProps}
            id={id}
            InputProps={{
                startAdornment: (
                    <ColorPicker onChange={handleChange} color={color} />
                ),
                endAdornment: props.renderendadorment && props.renderendadorment
            }}
            onChange={handleChangeInputValue}
            value={color === null ? "" : color}
            sx={{
                input: { ml: 1 },
            }}
        />
    );
};

export default GPColorPicker;

export const FETCH_SUCCESS = "DN/ARTICLES/FETCH_SUCCESS";
export const TABLE_UPDATE = "DN/ARTICLES/TABLE_UPDATE";

const types = {
    FETCH_SUCCESS,
    FETCH_FAILED: "DN/ARTICLES/FETCH_FAILED",
    FETCH_REQUEST: "DN/ARTICLES/FETCH_REQUEST",

    UPDATE_FAILED: "DN/ARTICLES/UPDATE_FAILED",
    UPDATE_REQUEST: "DN/ARTICLES/UPDATE_REQUEST",
    UPDATE_SUCCESS: "DN/ARTICLES/UPDATE_SUCCESS",

    CREATE_REQUEST: "DN/ARTICLES/CREATE_REQUEST",
    CREATE_SUCCESS: "DN/ARTICLES/CREATE_SUCCESS",
    CREATE_FAILED: "DN/ARTICLES/CREATE_FAILED",

    DELETE_REQUEST: "DN/ARTICLES/DELETE_REQUEST",
    DELETE_SUCCESS: "DN/ARTICLES/DELETE_SUCCESS",
    DELETE_FAILED: "DN/ARTICLES/DELETE_FAILED",

    GET_FAILED: "DN/ARTICLES/GET_FAILED",
    GET_REQUEST: "DN/ARTICLES/GET_REQUEST",
    GET_SUCCESS: "DN/ARTICLES/GET_SUCCESS",

    TABLE_UPDATE,
};

export default types;

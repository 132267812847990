import { registerRoutings } from "rootRouting";
import { permissionsType, routingType } from "types/configTypes";
import IConfig from "interfaces/IConfig";
import { registerProjects } from "rootProjects";
import AsyncComponent from "ui/AsyncComponent";
import articleConfig from "./Articles/config";
import { registerModule } from "moduleConfig";

export const permissionKey = "dn";
export const url = `/dn`;

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            read: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.length > 0) {
        const routingChildrens = [];
        const ac = articleConfig(modulePermissions, url);
        registerModule("DN.articles", ac.config);
        routingChildrens.push(...ac.routing);

        routing.push({
            path: url + "/*",
            children: routingChildrens,
            element: <AsyncComponent path="DN/index" />,
        });

        registerProjects([
            {
                id: permissionKey,
                name: "DN",
                icon: "newspaper",
                url: `${url}/articles`,
                priority: 900,
            },
        ]);
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;

import RestaurantSelectModal from "./modals/RestaurantSelectModal"
import RestaurantSaga from "./saga";

import IConfig from "interfaces/IConfig";
import { registerModals } from "modals";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const permissionKey = "booking.restaurant";
export const url = "restaurants";

const getConfig = (permissions: permissionsType, parentUrl: string) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            read: false,
            update: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    // if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
    config.permissions.read = true;
    registerModals({
        RestaurantSelectModal,
    });
    store.injectSaga?.("RestauarntSaga", RestaurantSaga);

    routing.push({
        path: url,
        element: (
            <AsyncComponent path="Booking/Restaurant/pages/BookingRestaurantIndexPage" />
        ),
    });
    // }

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => parentUrl + "/" + item.path);
    }

    return { config, routing };
};

export default getConfig;

import AddTranslationModal from "app/Translate/modals/AddTranslationModal";
import TranslateReducer from "app/Translate/reducers/TranslateTableReducer";
import TranslateSaga from "app/Translate/saga";

import IConfig from "interfaces/IConfig";
import { registerModals } from "modals";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const permissionKey = "translation";
export const url = "translate";

const getConfig = (permissions: permissionsType, parentUrl: string) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            read: false,
            update: false,
            create: false,
            delete: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".create") !== -1) {
        config.permissions.create = true;

        routing.push({
            path: `${url}/create`,
            element:  <AsyncComponent path="Translate/pages/TranslateCreatePage" />
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
        config.permissions.delete = true;
    }

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;
        registerModals({
            AddTranslationModal,
        });
        store.reducerManager?.add("TranslationTable", TranslateReducer);
        store.injectSaga?.("TranslateSaga", TranslateSaga);

        routing.push({
            path: url,
            element: (
                <AsyncComponent path="Translate/pages/TranslateIndexPage" />
            ),
        });
    }


    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;
    }
    if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
        config.permissions.delete = true;
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => parentUrl + "/" + item.path);
    }

    return { config, routing };
};

export default getConfig;

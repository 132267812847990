import { countFilters, getDefaultFilters } from "helpers/reducers";
import { flatten, uniq } from "lodash";
import store from "rootStore";

export function prepareColumns(data: any) {
    const state = store.getState()[data.id];

    const columns = data.columns.reduce((cols: any, column: any) => {
        let key = "align";
        if (column.align === "right") {
            column.cellProps = { align: column.align };
        } else if (column.align === "left") {
            column.cellProps = { align: column.align };
        }
        delete column[key];
        cols.push(column);
        return cols;
    }, []);

    const { withParams, withCountParams } = data.columns.reduce(
        (
            params: { withParams: string[]; withCountParams: string[] },
            column: any
        ) => {
            if (column.additionalRelations.length !== 0) {
                params.withParams = [
                    ...params.withParams,
                    ...column.additionalRelations,
                ];
            }

            const splited = column.field.split(".");
            if (splited.length > 1) {
                const columnName = splited.pop();
                if (columnName === "_count") {
                    params.withCountParams.push(splited.join("."));
                } else {
                    params.withParams.push(splited.join("."));
                }
            }

            return params;
        },
        { withParams: [], withCountParams: [] }
    );

    let defaultFilters: { [key: string]: any } = {
        ...state.filters,
        ...getDefaultFilters(data.filters_data),
    };

    return {
        id: data.id,
        availableColumns: data.available_columns,
        columns,
        filterData: data.filters_data,
        filters: defaultFilters,
        filterSettings: {
            ...state.filterSettings,
            total: countFilters(data.filters_data, defaultFilters),
        },
        with: uniq(flatten(withParams)),
        withCount: uniq(flatten(withCountParams)),
    };
}

import { useMemo } from "react";
import { Icon, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { sortBy } from "lodash";
import { useDispatch } from "react-redux";
import { modalShow } from "app/App/actions";

let projects = [
    {
        id: "dashboard",
        name: "Dashboard",
        url: "/dashboard",
        icon: "dashboard",
        priority: 100,
    },
];

export function registerProjects(data) {
    projects.push(...data);
}

export function getProjects() {
    return projects;
}

const AppProjects = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    // const [anchorEl, setAnchorEl] = useState();
    // const open = Boolean(anchorEl);

    const selected = useMemo(() => {
        return projects.find((item) => {
            return location.pathname.indexOf(item.url) === 0;
        })?.id;
    }, [location.pathname]);

    const projectsSorted = useMemo(() => {
        return sortBy(projects, ["priority", "name"]);
    }, []);

    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };

    const handleChangeProject = (url) => () => {
        navigate(url);
    };

    const handleClickAllProjects = () => {
        dispatch(modalShow("ProjectsModal"));
    };

    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    // const renderProject = (project) => {
    //     return (
    //         <MenuItem
    //             key={project.id}
    //             onClick={handleChangeProject(project.url)}
    //         >
    //             <ListItemIcon>
    //                 <Icon fontSize="small">{project.icon}</Icon>
    //             </ListItemIcon>
    //             <ListItemText>{project.name}</ListItemText>
    //         </MenuItem>
    //     );
    // };

    const renderProjectItem = (project) => {
        return (
            <Tooltip key={project.id} title={project.name}>
                <IconButton
                    onClick={handleChangeProject(project.url)}
                    sx={(theme) => ({
                        // backgroundColor:
                        //     selected === project.id && theme.palette.gray2.dark,
                        color:
                            selected === project.id
                                ? theme.palette.warning.light
                                : "#FFF",
                    })}
                >
                    <Icon>{project.icon}</Icon>
                    {selected === project.id && (
                        <Typography sx={{ pl: 1, color: "#FFF" }}>
                            {project.name}
                        </Typography>
                    )}
                </IconButton>
            </Tooltip>
        );
    };

    return (
        <Stack direction="row" overflow="auto">
            <IconButton onClick={handleClickAllProjects} sx={{ color: "#FFF" }}>
                <Icon>apps</Icon>
            </IconButton>
            {projectsSorted.map((item) => renderProjectItem(item))}
        </Stack>
    );

    // return (
    //     <div>
    //         <Button
    //             color="light"
    //             onClick={handleClick}
    //             variant="outlined"
    //             startIcon={!!selected ? <Icon>{selected.icon}</Icon> : null}
    //             endIcon={<Icon>keyboard_arrow_down</Icon>}
    //         >
    //             {!!selected ? selected.name : ""}
    //         </Button>
    //         <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
    //             {projectsSorted.map((item) => renderProject(item))}
    //         </Menu>
    //     </div>
    // );
};

export default AppProjects;

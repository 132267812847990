import React, { forwardRef, useContext, useEffect, useState } from "react";
import { TextFieldProps } from "@mui/material/TextField";

import FormContext from "./FormContext";
import { getHelperText, getValue } from "./helpers";
import {
    Box,
    FormControl,
    FormHelperText,
    Icon,
    IconButton,
    InputLabel,
    Tab,
    Tabs,
} from "@mui/material";
import trans from "helpers/trans";
import {
    convertFromRaw,
    convertToRaw,
    Editor,
    EditorState,
    RichUtils,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import PropTypes from "prop-types";

export interface IGPTranslationsWysywig
    extends Omit<TextFieldProps, "onChange"> {
    errorText?: string;
    formControlProps?: object;
    helperText?: string;
    labelProps?: object;
    stripPastedStyles?: boolean;
    label?: string;
    value?: any;
    id?: string;
    onChange?: Function;
    prefixId: string | string[];
}
const initEditor = (value: string) => {
    if (!value) return EditorState.createEmpty();
    try {
        return EditorState.createWithContent(convertFromRaw(JSON.parse(value)));
    } catch (e) {
        return EditorState.createEmpty();
    }
};

const GPTranslationsWysywig = forwardRef<any, IGPTranslationsWysywig>(
    (props, ref) => {
        const {
            errorText,
            formControlProps,
            fullWidth,
            helperText,
            label,
            labelProps,
            margin,
            onChange,
            value,
            variant,
            prefixId,
        } = props;
        const [tabsValue, setTabsValue] = useState<number>(0);
        const context = useContext(FormContext);

        const languages = ["en", "nb"];

        const id = `${prefixId}_${languages[tabsValue]}`;

        const handleChangeTabs = (
            event: React.SyntheticEvent,
            newValue: number
        ) => {
            setTabsValue(newValue);
        };

        const [editor, setEditor] = useState(
            initEditor(getValue(id, context, value))
        );
        useEffect(() => {
            setEditor(initEditor(getValue(id, context, value)));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [tabsValue]);

        const onChangeValue = (editor: any) => {
            const inputValue = draftToHtml(
                convertToRaw(editor.getCurrentContent())
            );
            const draftJSON = JSON.stringify(
                convertToRaw(editor.getCurrentContent())
            );
            if (!!onChange) {
                onChange(
                    {
                        id,
                        value: inputValue,
                    },
                    context,
                    draftJSON
                );
            } else {
                context?.onChange({
                    id,
                    value: inputValue,
                });
            }

            setEditor(editor);
        };

        const onClickBold = () => {
            onChangeValue(RichUtils.toggleInlineStyle(editor, "BOLD"));
        };
        const onClickItalic = () => {
            onChangeValue(RichUtils.toggleInlineStyle(editor, "ITALIC"));
        };
        const onClickStrikeThrough = () => {
            onChangeValue(RichUtils.toggleInlineStyle(editor, "STRIKETHROUGH"));
        };
        const onClickUnderline = () => {
            onChangeValue(RichUtils.toggleInlineStyle(editor, "UNDERLINE"));
        };

        const handleKeyCommand = (command: string) => {
            const newState = RichUtils.handleKeyCommand(editor, command);

            if (newState) {
                onChangeValue(newState);
                return "handled";
            }

            return "not-handled";
        };

        const { inputHelperText, isError } = getHelperText(
            id,
            context,
            errorText,
            helperText
        );

        const activeToolbarButtons = editor.getCurrentInlineStyle();
        return (
            <>
                <Tabs
                    value={tabsValue}
                    onChange={handleChangeTabs}
                    centered
                    variant="fullWidth"
                >
                    {languages.map((lang: string, idx: number) => {
                        return (
                            <Tab
                                key={`tab-${lang}-${idx}`}
                                label={trans(`global.lang.${lang}`)}
                            />
                        );
                    })}
                </Tabs>
                <Box key={tabsValue} mt={2}>
                    <FormControl
                        {...formControlProps}
                        error={isError}
                        fullWidth={fullWidth}
                        margin={margin}
                        variant={variant}
                    >
                        {!!label && (
                            <InputLabel
                                {...labelProps}
                                id={`${id}-label`}
                                shrink
                            >
                                {label}
                            </InputLabel>
                        )}

                        <Box
                            sx={{
                                border: (theme) =>
                                    `1px solid ${theme.palette.grey[400]}`,
                                borderRadius: 1,
                                "& .public-DraftEditor-content": {
                                    minHeight: 48,
                                },
                                "& .public-DraftStyleDefault-block": {
                                    margin: "0!important",
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    alignItems: "center",
                                    borderBottom: (theme) =>
                                        `1px solid ${theme.palette.grey[300]}`,
                                    color: "text.secondary",
                                    display: "flex",
                                    width: "100%",
                                }}
                            >
                                <IconButton
                                    onClick={onClickBold}
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    <Icon
                                        sx={[
                                            activeToolbarButtons.has(
                                                "BOLD"
                                            ) && {
                                                color: "#000",
                                            },
                                        ]}
                                    >
                                        format_bold
                                    </Icon>
                                </IconButton>
                                <IconButton
                                    onClick={onClickItalic}
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    <Icon
                                        sx={[
                                            activeToolbarButtons.has(
                                                "ITALIC"
                                            ) && {
                                                color: "#000",
                                            },
                                        ]}
                                    >
                                        format_italic
                                    </Icon>
                                </IconButton>
                                <IconButton
                                    onClick={onClickUnderline}
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    <Icon
                                        sx={[
                                            activeToolbarButtons.has(
                                                "UNDERLINE"
                                            ) && {
                                                color: "#000",
                                            },
                                        ]}
                                    >
                                        format_underline
                                    </Icon>
                                </IconButton>
                                <IconButton
                                    onClick={onClickStrikeThrough}
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    <Icon
                                        sx={[
                                            activeToolbarButtons.has(
                                                "STRIKETHROUGH"
                                            ) && {
                                                color: "#000",
                                            },
                                        ]}
                                    >
                                        strikethrough_s
                                    </Icon>
                                </IconButton>
                            </Box>
                            <Box sx={{ p: 1 }}>
                                <Editor
                                    editorState={editor}
                                    handleKeyCommand={handleKeyCommand}
                                    onChange={onChangeValue}
                                    stripPastedStyles={props.stripPastedStyles}
                                />
                            </Box>
                        </Box>

                        {inputHelperText.length > 0 && (
                            <FormHelperText>{inputHelperText}</FormHelperText>
                        )}
                    </FormControl>
                </Box>
            </>
        );
    }
);

GPTranslationsWysywig.propTypes = {
    errorText: PropTypes.string,
    formControlProps: PropTypes.object,
    helperText: PropTypes.string,
    id: PropTypes.string.isRequired,
    labelProps: PropTypes.object,
    onChange: PropTypes.func,
    stripPastedStyles: PropTypes.bool,
};

GPTranslationsWysywig.defaultProps = {
    errorText: "",
    formControlProps: {},
    helperText: "",
    labelProps: {},
    stripPastedStyles: true,
};

export default GPTranslationsWysywig;

import DNArticleSaga from "./saga";
import DNArticleTableReducer from "./reducers/DNArticleTableReducer";
import AsyncComponent from "ui/AsyncComponent";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";
import IConfig from "interfaces/IConfig";

export const permissionKey = "dn.articles";
export const url = "articles";

const getConfig = (permissions: permissionsType, parentUrl: string) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            read: false,
            update: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        store.reducerManager?.add("DnArticleTable", DNArticleTableReducer);
        store.injectSaga?.("DnArticleSaga", DNArticleSaga);

        routing.push({
            path: url,
            element: (
                <AsyncComponent path="DN/Articles/pages/DNArticleIndexPage" />
            ),
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => parentUrl + "/" + item.path);
    }

    return { config, routing };
};

export default getConfig;

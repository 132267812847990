import {
    potentialClientsBulkSeller,
    potentialClientsTableUpdate,
} from "../actions";
import { ISelectionActionsModal } from "../interfaces";

import { Icon, MenuItem, Stack } from "@mui/material";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import { sellersFetchAll } from "app/Sellers/actions";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { FC, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    Form,
    Loader,
    LoadingButton,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    RequestMessage,
    Select,
    Settings,
    SettingsItem,
} from "ui";
import { IGPFormRef } from "ui/Form/Form";

const PotentialClientsSellerModal: FC<ISelectionActionsModal> = ({
    selected,
    onSuccess,
}) => {
    const { currentData, isLoading } = useRequest(
        sellersFetchAll({
            params: { _sort: { "user.name": "asc" }, _with: "user.account" },
        })
    );
    const {
        errors: errorsSeller,
        isLoading: isLoadingSeller,
        request: requestSeller,
        message,
        status,
    } = useRequest();

    const formRef = useRef<IGPFormRef | null>();
    const dispatch = useDispatch();

    const handleCloseModal = () =>
        dispatch(modalHide("PotentialClientsSellerModal"));
    const handleSeller = () => {
        if (formRef.current !== null) handleSubmit(formRef?.current?.getData());
    };
    const handleSubmit = (data: any) => {
        requestSeller(
            potentialClientsBulkSeller(
                { ...data, ids: selected },
                (response: AxiosResponse) => {
                    if (response.status < 300) {
                        dispatch(modalHide("PotentialClientsSellerModal"));
                        dispatch(potentialClientsTableUpdate("selection", []));
                        onSuccess();
                    }
                }
            )
        );
    };
    const renderSeller = () => {
        if (!currentData) return [];
        return currentData?.data?.map((seller: any) => {
            return (
                <MenuItem
                    value={seller.user_id}
                    key={`seller-${seller.user_id}`}
                >
                    {seller.user?.name}
                </MenuItem>
            );
        });
    };

    if (isLoading) return <Loader />;

    return (
        <Modal maxWidth="md" fullWidth open>
            <ModalTitle onClose={handleCloseModal}>
                {trans(
                    "admin.potentialClients.modal.potentialClientsSellerModal.title"
                )}
            </ModalTitle>
            <ModalContent sx={{ display: "flex", flexDirection: "column" }}>
                <RequestMessage
                    message={message}
                    status={status}
                    sx={{
                        mb: 1,
                    }}
                />
                <Form
                    ref={formRef}
                    data={currentData?.data}
                    errors={errorsSeller}
                    fields={{
                        seller_id: {},
                    }}
                    loading={isLoadingSeller}
                    onSubmit={handleSubmit}
                    unsaved={false}
                >
                    <Settings>
                        <SettingsItem
                            label={trans(
                                "admin.potentialClients.modal.potentialClientsSellerModal.seller"
                            )}
                        >
                            <Select fullWidth id="seller_id">
                                <MenuItem value={0}>
                                    <em>
                                        {trans(
                                            "admin.potentialClients.modal.potentialClientsSellerModal.select"
                                        )}
                                    </em>
                                </MenuItem>
                                {renderSeller()}
                            </Select>
                        </SettingsItem>
                    </Settings>
                </Form>
            </ModalContent>
            <ModalActions
                sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    p: 0,
                }}
            >
                <Stack
                    direction="row"
                    sx={{
                        backgroundColor: "grey.200",
                        marginLeft: "0!important",
                        p: 1,
                        width: "100%",
                    }}
                >
                    <LoadingButton
                        loading={isLoading}
                        loadingPosition="start"
                        fullWidth
                        onClick={handleSeller}
                        startIcon={<Icon>save</Icon>}
                    >
                        {trans("global.button.save")}
                    </LoadingButton>
                </Stack>
            </ModalActions>
        </Modal>
    );
};

export default PotentialClientsSellerModal;

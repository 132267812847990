import { IPermission, IPermissionsTable } from "../interfaces";

import { Box, ListItemText, Paper } from "@mui/material";
import { set } from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import { Checkbox, PaperHeader, Table } from "ui";

type TPermissions = {
    [key: string]: any;
};

const PermissionsTable: FC<IPermissionsTable> = ({
    onChange,
    permissions,
    selected,
}) => {
    const [permissionsHeight, setPermissionsHeight] = useState<number>(400);
    const [dependencies, setDependencies] = useState<number[]>([]);

    useEffect(() => {
        const windowHeight = window.innerHeight;
        const element = document.getElementById("Permissions");
        if (!!element) {
            setPermissionsHeight(
                windowHeight - element.offsetTop - 80 - 32 - 16 - 62
            );
        }
    }, []);

    const permissionById: { [key: string]: IPermission } = useMemo(() => {
        let tempPermissions: { [key: string]: IPermission } = {};

        permissions?.forEach((item: IPermission) => {
            tempPermissions[item.id] = item;
        });

        return tempPermissions;
    }, [permissions]);

    useEffect(() => {
        setDependencies(() => {
            let list: number[] = [];
            selected.forEach((permissionId: number) => {
                const permission = permissionById[permissionId];
                if (permission.dependencies.length) {
                    list = [...list, ...permission.dependencies];
                }
            });

            list = list.filter((v, i, a) => a.indexOf(v) === i);
            return list;
        });
    }, [permissionById, selected]);

    const list = useMemo(() => {
        let tempPermissions: TPermissions = {};

        permissions?.forEach((item: IPermission) => {
            const parts = item.name.split(".");
            if (parts.length > 0) {
                if (
                    ["create", "read", "update", "delete"].indexOf(
                        parts[parts.length - 1]
                    ) !== -1
                ) {
                    set(
                        tempPermissions,
                        parts.slice(0, -1).join("__") +
                            ".crud." +
                            parts[parts.length - 1],
                        item
                    );
                } else {
                    set(tempPermissions, parts.join("__"), item);
                }
            }
        });

        let list: Object[] = [];
        let group: string = "";
        Object.keys(tempPermissions)
            .sort()
            .forEach((item: string) => {
                const parts = item.split("__");
                if (parts.length === 1 && !!tempPermissions[item].crud) {
                    list.push({
                        path: parts[0],
                        group: true,
                        crud: tempPermissions[item].crud,
                    });
                    group = parts[0];
                    return;
                } else if (group !== parts[0]) {
                    list.push({ path: parts[0], group: true });
                    group = parts[0];
                }

                if (!!tempPermissions[item].crud) {
                    list.push({
                        path: parts.join("."),
                        crud: tempPermissions[item].crud,
                    });
                } else {
                    list.push({
                        path: parts.join("."),
                        item: tempPermissions[item],
                    });
                }
            });

        return list;
    }, [permissions]);

    const updateSelectedPermissions = (permissionId: number | number[]) => {
        let newPermissions: number[] = [];

        if (Array.isArray(permissionId)) {
            const temp = selected.filter(
                (item: number) => permissionId.indexOf(item) === -1
            );

            if (temp.length > selected.length - permissionId.length) {
                newPermissions = [...temp, ...permissionId];
            } else {
                newPermissions = selected.filter(
                    (item: number) => permissionId.indexOf(item) === -1
                );
            }
        } else {
            if (selected.indexOf(permissionId) !== -1) {
                newPermissions = selected.filter(
                    (item: number) => item !== permissionId
                );
            } else {
                newPermissions = [...selected, permissionId];
            }
        }

        //add dependecies
        newPermissions.forEach((item: number) => {
            if (permissionById[item].dependencies.length > 0) {
                newPermissions = [
                    ...newPermissions,
                    ...permissionById[item].dependencies,
                ];
            }
        });

        onChange(newPermissions.filter((v, i, a) => a.indexOf(v) === i));
    };

    const handleChangeCheckbox = (
        event: React.ChangeEvent<HTMLInputElement>,
        item: IPermission
    ) => {
        updateSelectedPermissions(item.id);
    };

    const handleOnClickRow = (
        event: React.MouseEvent<Element, MouseEvent>,
        item: any
    ) => {
        const target = event.target as HTMLElement;

        if (
            target.tagName === "LABEL" ||
            target.classList.contains("Mui-disabled")
        ) {
            return;
        }

        let toSelect: number[] = [];

        if (!!item.crud) {
            Object.keys(item.crud).forEach((crudItem: string) => {
                toSelect.push(item.crud[crudItem].id);
            });
        } else if (!!item.item) {
            toSelect.push(item.item.id);
        } else {
            permissions
                .filter(
                    (permission: IPermission) =>
                        permission.name.indexOf(item.path + ".") === 0
                )
                .forEach((permission: IPermission) => {
                    toSelect.push(permission.id);
                });
        }

        toSelect = toSelect.filter((item: number) => {
            return dependencies.indexOf(item) === -1;
        });

        if (toSelect.length > 0) {
            updateSelectedPermissions(toSelect);
        }
    };

    const renderSelectAll = () => {
        return (
            <Checkbox
                id="select-all"
                indeterminate={
                    selected.length > 0 &&
                    permissions.length !== selected.length
                }
                checked={permissions.length === selected.length}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateSelectedPermissions(
                        permissions.map((item: IPermission) => item.id)
                    );
                }}
                onClick={(event) => event.stopPropagation()}
                sx={{ p: 0 }}
            />
        );
    };

    return (
        <Paper id="Permissions">
            <PaperHeader title="Permissions" actions={renderSelectAll()} />
            <Table
                id="PermissionsTable"
                columns={[
                    {
                        field: "name",
                        cellProps: {
                            size: "small",
                        },
                        renderCell: (item, column, idx) => {
                            return {
                                children: (
                                    <Box sx={{ pl: !item.group ? 4 : 0 }}>
                                        <ListItemText
                                            primary={item.path}
                                            primaryTypographyProps={{
                                                sx: {
                                                    fontWeight: item.group
                                                        ? 700
                                                        : 400,
                                                },
                                            }}
                                        />
                                        <ListItemText
                                            secondary={item.item?.description}
                                            secondaryTypographyProps={{
                                                fontSize: 12,
                                            }}
                                        />
                                    </Box>
                                ),
                            };
                        },
                    },
                    {
                        field: "custom",
                        headName: "",
                        cellProps: {
                            align: "center",
                            size: "small",
                            sx: {
                                verticalAlign: "middle",
                            },
                            width: 40,
                        },
                        renderCell: (item, column, idx) => {
                            if (!item.item) {
                                return {
                                    children: null,
                                };
                            }

                            const checked =
                                selected.indexOf(item.item.id) !== -1;

                            return {
                                children: (
                                    <Checkbox
                                        id={`permissions.${item.item.id}-${idx}`}
                                        key={`permissions-${item.item.id}-${idx}`}
                                        checked={checked}
                                        disabled={
                                            dependencies.indexOf(
                                                item.item.id
                                            ) !== -1
                                        }
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => handleChangeCheckbox(e, item.item)}
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                        sx={{ p: 0 }}
                                    />
                                ),
                            };
                        },
                    },
                    {
                        field: "create",
                        headName: "Create",
                        cellProps: {
                            align: "center",
                            size: "small",
                            sx: {
                                verticalAlign: "middle",
                            },
                            width: 40,
                        },
                        renderCell: (item, column, idx) => {
                            if (!item.crud?.create) {
                                return {
                                    children: null,
                                };
                            }

                            const checked =
                                selected.indexOf(item.crud.create.id) !== -1;

                            return {
                                children: (
                                    <Checkbox
                                        id={`permissions.${item.crud.create.id}-${idx}`}
                                        key={`permissions-${item.crud.create.id}-${idx}`}
                                        checked={checked}
                                        disabled={
                                            dependencies.indexOf(
                                                item.crud.create.id
                                            ) !== -1
                                        }
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) =>
                                            handleChangeCheckbox(
                                                e,
                                                item.crud.create
                                            )
                                        }
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                        sx={{ p: 0 }}
                                    />
                                ),
                            };
                        },
                    },
                    {
                        field: "read",
                        headName: "Read",
                        cellProps: {
                            align: "center",
                            size: "small",
                            sx: {
                                verticalAlign: "middle",
                            },
                            width: 40,
                        },
                        renderCell: (item, column, idx) => {
                            if (!item.crud?.read) {
                                return {
                                    children: null,
                                };
                            }
                            const checked =
                                selected.indexOf(item.crud.read.id) !== -1;

                            return {
                                children: (
                                    <Checkbox
                                        id={`permissions.${item.crud.read.id}-${idx}`}
                                        key={`permissions-${item.crud.read.id}-${idx}`}
                                        checked={checked}
                                        disabled={
                                            dependencies.indexOf(
                                                item.crud.read.id
                                            ) !== -1
                                        }
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) =>
                                            handleChangeCheckbox(
                                                e,
                                                item.crud.read
                                            )
                                        }
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                        sx={{ p: 0 }}
                                    />
                                ),
                            };
                        },
                    },
                    {
                        field: "update",
                        headName: "Update",
                        cellProps: {
                            align: "center",
                            size: "small",
                            sx: {
                                verticalAlign: "middle",
                            },
                            width: 40,
                        },
                        renderCell: (item, column, idx) => {
                            if (!item.crud?.update) {
                                return {
                                    children: null,
                                };
                            }
                            const checked =
                                selected.indexOf(item.crud.update.id) !== -1;
                            return {
                                children: (
                                    <Checkbox
                                        id={`permissions.${item.crud.update.id}-${idx}`}
                                        key={`permissions-${item.crud.update.id}-${idx}`}
                                        checked={checked}
                                        disabled={
                                            dependencies.indexOf(
                                                item.crud.update.id
                                            ) !== -1
                                        }
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) =>
                                            handleChangeCheckbox(
                                                e,
                                                item.crud.update
                                            )
                                        }
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                        sx={{ p: 0 }}
                                    />
                                ),
                            };
                        },
                    },
                    {
                        field: "delete",
                        headName: "Delete",
                        cellProps: {
                            align: "center",
                            size: "small",
                            sx: {
                                verticalAlign: "middle",
                            },
                            width: 40,
                        },
                        renderCell: (item, column, idx) => {
                            if (!item.crud?.delete) {
                                return {
                                    children: null,
                                };
                            }
                            const checked =
                                selected.indexOf(item.crud.delete.id) !== -1;

                            return {
                                children: (
                                    <Checkbox
                                        id={`permissions.${item.crud.delete.id}-${idx}`}
                                        key={`permissions-${item.crud.delete.id}-${idx}`}
                                        checked={checked}
                                        disabled={
                                            dependencies.indexOf(
                                                item.crud.delete.id
                                            ) !== -1
                                        }
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) =>
                                            handleChangeCheckbox(
                                                e,
                                                item.crud.delete
                                            )
                                        }
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                        sx={{ p: 0 }}
                                    />
                                ),
                            };
                        },
                    },
                ]}
                rows={list}
                onClickRow={handleOnClickRow}
                tableContainerProps={{ sx: { maxHeight: permissionsHeight } }}
            />
        </Paper>
    );
};

export default PermissionsTable;

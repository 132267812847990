import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

interface IGPUIButton extends Omit<ButtonProps, "component"> {
    component?: any;
    target?: string;
    to?: string;
}

const StyledButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
})) as typeof Button;

const GPUIButton = ({ children, ...restProps }: IGPUIButton) => {
    return <StyledButton {...restProps}>{children}</StyledButton>;
};

GPUIButton.defaultProps = {
    variant: "contained",
};

export default GPUIButton;

import { FC } from "react";
import { Box, Typography } from "@mui/material";

const Dashboard: FC = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
        >
            <Typography fontSize={32}>Dashboard</Typography>
        </Box>
    );
};

export default Dashboard;

import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const requests = {
    destroy: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.delete(`/translations/${url.id}`, config),
    fetch: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/translations/${url.project}`, config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/translations/${url.project}/${url.id}`, config),
    store: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.post(`/translations/${url.project}`, data, config),
    google: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.post(`/translations/${url.project}/google`, data, config),
    toService: (config: AxiosRequestConfig, url: RequestUrlId) => {
        return api.get(`/translations/sync/${url.project}`, config);
    },
    update: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`/translations/${url.project}/${url.id}`, data, config),
    patch: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.patch(`/translations/${url.project}/${url.id}`, data, config),
};

export default requests;

import api from "./api";
import types from "./types";

import { request } from "api/apiSaga";
import { snackbarShow } from "app/App/actions";
import { AnyAction } from "redux";
import { call, takeLatest, put } from "redux-saga/effects";

function* fetch(action: AnyAction) {
    yield call(request, api.fetch, action);
}

function* get(action: AnyAction) {
    yield call(request, api.get, action);
}

function* update(action: AnyAction): any {
    const response = yield call(request, api.update, action, "put");

    if (response.status === 200) {
        yield put(
            snackbarShow({
                content: "DN Article has been updated",
            })
        );
    }
}

export default function* saga() {
    yield takeLatest(types.FETCH_REQUEST, fetch);
    yield takeLatest(types.GET_REQUEST, get);
    yield takeLatest(types.UPDATE_REQUEST, update);
}

import { registerRoutings } from "rootRouting";
import { permissionsType, routingType } from "types/configTypes";
import IConfig from "interfaces/IConfig";
import { registerProjects } from "rootProjects";
import AsyncComponent from "ui/AsyncComponent";
import restaurantConfig from "./Restaurant/config";
import predefinedListsConfig from "./PredefinedLists/config";
import { registerModule } from "moduleConfig";

export const permissionKey = "booking";
export const url = `/booking`;

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            read: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.length > 0) {
        const routingChildrens = [];

        //register Restaurant module
        const bc = restaurantConfig(modulePermissions, url);
        registerModule(`${permissionKey}.restaurant`, bc.config);
        // routingChildrens.push(...bc.routing);

        //register Predefined Lists module
        const pl = predefinedListsConfig(modulePermissions, url);
        registerModule(`${permissionKey}.predefined-lists`, pl.config);
        routingChildrens.push(...pl.routing);

        routing.push({
            path: url + "/*",
            children: routingChildrens,
            element: <AsyncComponent path="Booking/index" />,
        });

        registerProjects([
            {
                id: permissionKey,
                name: "Booking",
                icon: "table_restaurant",
                url: `${url}`,
                priority: 200,
            },
        ]);
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;

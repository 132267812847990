import PotentialClientsStatusModal from "./modals/PotentialClientsStatusModal";
import PotentialClientsSellerModal from "./modals/PotentialClientsSellerModal";
import PotentialClientsTable from "./reducers/PotentialClientsTableReducer";
import PotentialClientsSaga from "./saga";
import AsyncComponent from "ui/AsyncComponent";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { registerProjects } from "rootProjects";
import { registerModals } from "modals";
export const permissionKey = "potential-clients";
export const url = "/potential-clients";

const getConfig = (permissions) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config = {
        permissions: {
            create: false,
            delete: false,
            read: false,
            update: false,
            letters: false,
        },
        routing: [],
        url,
    };

    let routing = [];

    if (modulePermissions.indexOf(permissionKey + ".create") !== -1) {
        config.permissions.create = true;

        routing.push({
            path: `${url}/create`,
            element: (
                <AsyncComponent path="PotentialClients/pages/PotentialClientsCreatePage" />
            ),
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
        config.permissions.delete = true;
    }
    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        store.reducerManager.add(
            "PotentialCustomersTable",
            PotentialClientsTable
        );
        store.injectSaga("PotentialClientsSaga", PotentialClientsSaga);

        routing.push({
            path: url,
            element: (
                <AsyncComponent path="PotentialClients/pages/PotentialClientsIndexPage" />
            ),
        });
        if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
            config.permissions.update = true;
            registerModals({
                PotentialClientsStatusModal,
                PotentialClientsSellerModal,
            });
            routing.push({
                path: `${url}/:id/edit`,
                element: (
                    <AsyncComponent path="PotentialClients/pages/PotentialClientsEditPage" />
                ),
            });
        }
        if (modulePermissions.indexOf(permissionKey + ".letters") !== -1) {
            config.permissions.letters = true;
        }
        registerProjects([
            {
                id: permissionKey,
                name: "Potential Clients",
                icon: "point_of_sale",
                url,
                priority: 900,
            },
        ]);
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;

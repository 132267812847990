import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const requests = {
    fetch: (config: AxiosRequestConfig) => api.get("/dn/articles", config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/dn/articles/${url.id}`, config),
    update: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`/dn/articles/${url.id}`, data, config),
};

export default requests;

export const TABLE_UPDATE = "TRANSLATE/TABLE_UPDATE";
export const FETCH_SUCCESS = "TRANSLATE_FETCH_SUCCESS";

const types = {
    TABLE_UPDATE,

    DESTROY_FAILED: "TRANSLATE_FAILED",
    DESTROY_REQUEST: "TRANSLATE_REQUEST",
    DESTROY_SUCCESS: "TRANSLATE_SUCCESS",

    FETCH_FAILED: "TRANSLATE_FETCH_FAILED",
    FETCH_REQUEST: "TRANSLATE_FETCH_REQUEST",
    FETCH_SUCCESS,

    GET_FAILED: "TRANSLATE_GET_FAILED",
    GET_REQUEST: "TRANSLATE_GET_REQUEST",
    GET_SUCCESS: "TRANSLATE_GET_SUCCESS",

    PATCH_FAILED: "TRANSLATE/PATCH_FAILED",
    PATCH_REQUEST: "TRANSLATE/PATCH_REQUEST",
    PATCH_SUCCESS: "TRANSLATE/PATCH_SUCCESS",
    
    TRANSLATE_GOOGLE_FAILED: "TRANSLATE_GOOGLE_FAILED",
    TRANSLATE_GOOGLE_REQUEST: "TRANSLATE_GOOGLE_REQUEST",
    TRANSLATE_GOOGLE_SUCCESS: "TRANSLATE_GOOGLE_SUCCESS",

    TRANSLATE_TO_SERVICE_FAILED: "TRANSLATE_TO_SERVICE_FAILED",
    TRANSLATE_TO_SERVICE_REQUEST: "TRANSLATE_TO_SERVICE_REQUEST",
    TRANSLATE_TO_SERVICE_SUCCESS: "TRANSLATE_TO_SERVICE_SUCCESS",

    STORE_FAILED: "TRANSLATE_STORE_FAILED",
    STORE_REQUEST: "TRANSLATE_STORE_REQUEST",
    STORE_SUCCESS: "TRANSLATE_STORE_SUCCESS",

    UPDATE_FAILED: "TRANSLATE_UPDATE_FAILED",
    UPDATE_REQUEST: "TRANSLATE_UPDATE_REQUEST",
    UPDATE_SUCCESS: "TRANSLATE_UPDATE_SUCCESS",
};

export default types;

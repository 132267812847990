import { TranslateModel } from "./interfaces";
import types from "./types";

import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";

export const translateCreate = (
    project: string,
    data: TranslateModel,
    onResponse?: Function
) =>
    request(
        types.STORE_REQUEST,
        types.STORE_FAILED,
        types.STORE_SUCCESS,
        "translateCreate",
        { data, url: { project } },
        onResponse
    );

export const translateGoogle = (
    project: string,
    data: TranslateModel,
    onResponse?: Function
) =>
    request(
        types.TRANSLATE_GOOGLE_REQUEST,
        types.TRANSLATE_GOOGLE_FAILED,
        types.TRANSLATE_GOOGLE_SUCCESS,
        "translateGoogle",
        { data, url: { project } },
        onResponse
    );

export const translateToService = (
    project: string,
    data?: TranslateModel,
    onResponse?: Function
) =>
    request(
        types.TRANSLATE_TO_SERVICE_REQUEST,
        types.TRANSLATE_TO_SERVICE_FAILED,
        types.TRANSLATE_TO_SERVICE_SUCCESS,
        "translateToService",
        { data, url: { project } },
        onResponse
    );

export const translateDelete = (
    id: number,
    onResponse?: Function
) =>
    request(
        types.DESTROY_REQUEST,
        types.DESTROY_FAILED,
        types.DESTROY_SUCCESS,
        "translateDelete",
        {
            url: {
                id,
            },
        },
        onResponse
    );

export const translateFetch = (project: string, config?: AxiosRequestConfig) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        "translateFetch",
        {
            config,
            url: {
                project,
            },
        }
    );

export const translateGet = (
    id: number,
    project: string,
    config?: AxiosRequestConfig,
    onResponse?: Function
) =>
    request(
        types.GET_REQUEST,
        types.GET_FAILED,
        types.GET_SUCCESS,
        "translateGet",
        {
            config,
            url: {
                project,
                id,
            },
        },
        onResponse
    );

export const translateTableUpdate = (action: string, data: any) => ({
    type: types.TABLE_UPDATE,
    action,
    data,
});

export const translateUpdate = (
    id: number,
    project: string,
    data: any,
    onResponse?: Function
) => {
    return request(
        types.UPDATE_REQUEST,
        types.UPDATE_FAILED,
        types.UPDATE_SUCCESS,
        "translateUpdate",
        { data, url: { id, project } },
        onResponse
    );
};

export const translatePatch = (id: number, project: string, data: any, onResponse?: Function) => {
    return request(
        types.PATCH_REQUEST,
        types.PATCH_FAILED,
        types.PATCH_SUCCESS,
        "translatePatch",
        { data, url: { id, project } },
        onResponse
    );
};


import trans from "helpers/trans";
import { FC } from "react";
import { Button, Modal, ModalActions, ModalContent } from "ui";
import { useDispatch } from "react-redux";
import {
    confirmationConfirmed,
    confirmationUnconfirmed,
    modalHide,
} from "../actions";
import { IConfirmationModal } from "../interfaces";

const ConfirmationModal: FC<IConfirmationModal> = ({
    message = trans("admin.app.modal.confirmationModal.message"),
    onClickAgree,
    onClickDisagree,
}) => {
    const dispatch = useDispatch();

    const handleClickAgree = () => {
        if (!!onClickAgree) {
            onClickAgree();
        }
        dispatch(confirmationConfirmed());
        dispatch(modalHide("ConfirmationModal"));
    };

    const handleClickDisagree = () => {
        if (!!onClickDisagree) {
            onClickDisagree();
        }
        dispatch(confirmationUnconfirmed());
        dispatch(modalHide("ConfirmationModal"));
    };

    const onClose = (
        event: Event | React.SyntheticEvent<any, Event>,
        reason: string
    ) => {
        return false;
    };

    return (
        <Modal maxWidth="xs" fullWidth onClose={onClose} open>
            <ModalContent>{message}</ModalContent>
            <ModalActions>
                <Button
                    onClick={handleClickDisagree}
                    variant="text"
                    color="secondary"
                    sx={{ fontWeight: 500 }}
                >
                    {trans("admin.app.modal.confirmationModal.button.disagree")}
                </Button>
                <Button
                    onClick={handleClickAgree}
                    variant="text"
                    color="secondary"
                >
                    {trans("admin.app.modal.confirmationModal.button.agree")}
                </Button>
            </ModalActions>
        </Modal>
    );
};

export default ConfirmationModal;

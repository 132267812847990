import { FC, useContext, useState } from "react";
import { IFormChangeResponse } from "./Form";
import FormContext from "./FormContext";
import { getValue } from "./helpers";
import TextField, { IGPTextField } from "./TextField";

export interface INumberField extends IGPTextField {
    precision?: number;
    value?: number | string | null;
}

const NumberField: FC<INumberField> = ({
    id,
    onChange,
    value,
    onBlur,
    precision = 0,
    ...restProps
}) => {
    const context = useContext(FormContext);
    const inputValue = getValue(id, context, value);

    const [val, setVal] = useState(
        inputValue || inputValue === 0
            ? (+inputValue + 0.0).toFixed(precision)
            : ""
    );

    const handleChange = ({ value }: IFormChangeResponse) => {
        const targetValue = value.replace(",", ".");

        const regex = new RegExp(`^\\d{0,16}([.]\\d{0,${precision}})?$`);
        if (targetValue.length === 0 || regex.test(targetValue)) {
            const intValue = parseFloat(targetValue);

            if (!!onChange) {
                onChange({
                    id,
                    value: intValue,
                });
            } else {
                context?.onChange({
                    id,
                    value: intValue,
                });
            }

            setVal(targetValue);
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!!onBlur) {
            onBlur(e);
        }

        let floatNumber = "";
        if (val !== "") {
            floatNumber = (+val + 0.0).toFixed(precision);
        }

        setVal(floatNumber);
    };

    return (
        <TextField
            {...restProps}
            id={id}
            inputProps={{ inputMode: "numeric" }}
            onChange={handleChange}
            value={val}
            onBlur={handleBlur}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
};

export default NumberField;

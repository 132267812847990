import { Icon, MenuItem, Stack } from "@mui/material";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { FC, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    Form,
    LoadingButton,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    RequestMessage,
    Select,
    Settings,
    SettingsItem,
} from "ui";
import { IGPFormRef } from "ui/Form/Form";
import { ISelectionActionsModal } from "../interfaces";
import {
    potentialClientsBulkStatus,
    potentialClientsTableUpdate,
} from "../actions";

const PotentialClientsStatusModal: FC<ISelectionActionsModal> = ({
    selected,
    onSuccess,
}) => {
    const { errors, isLoading, message, request, status } = useRequest();
    const formRef = useRef<IGPFormRef | null>();
    const dispatch = useDispatch();

    const handleCloseModal = () =>
        dispatch(modalHide("PotentialClientsStatusModal"));
    const handleStatus = () => {
        if (formRef.current !== null) handleSubmit(formRef?.current?.getData());
    };
    const handleSubmit = (data: any) => {
        request(
            potentialClientsBulkStatus(
                { ...data, ids: selected },
                (response: AxiosResponse) => {
                    if (response.status < 300) {
                        dispatch(modalHide("PotentialClientsStatusModal"));
                        dispatch(potentialClientsTableUpdate("selection", []));
                        onSuccess();
                    }
                }
            )
        );
    };

    return (
        <Modal maxWidth="md" fullWidth open>
            <ModalTitle onClose={handleCloseModal}>
                {trans(
                    "admin.potentialClients.modal.potentialClientsStatusModal.title"
                )}
            </ModalTitle>
            <ModalContent sx={{ display: "flex", flexDirection: "column" }}>
                <RequestMessage
                    message={message}
                    status={status}
                    sx={{
                        mb: 1,
                    }}
                />
                <Form
                    ref={formRef}
                    data={{}}
                    errors={errors}
                    fields={{
                        status: { default: "new" },
                    }}
                    loading={isLoading}
                    onSubmit={handleSubmit}
                    unsaved={false}
                >
                    <Settings>
                        <SettingsItem
                            label={trans(
                                "admin.potentialClients.modal.potentialClientsStatusModal.status"
                            )}
                        >
                            <Select fullWidth id="status">
                                <MenuItem value="">
                                    <em>
                                        {trans(
                                            "admin.potentialClients.modal.potentialClientsStatusModal.select"
                                        )}
                                    </em>
                                </MenuItem>
                                {[
                                    "new",
                                    "printed",
                                    "not_interested",
                                    "transferred",
                                ].map((item, idx) => {
                                    return (
                                        <MenuItem
                                            value={item}
                                            key={`status-${item}-${idx}`}
                                            id={`status-${item}-${idx}`}
                                        >
                                            {item === "not_interested"
                                                ? "not interested"
                                                : item}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </SettingsItem>
                    </Settings>
                </Form>
            </ModalContent>
            <ModalActions
                sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    p: 0,
                }}
            >
                <Stack
                    direction="row"
                    sx={{
                        backgroundColor: "grey.200",
                        marginLeft: "0!important",
                        p: 1,
                        width: "100%",
                    }}
                >
                    <LoadingButton
                        loading={isLoading}
                        loadingPosition="start"
                        fullWidth
                        onClick={handleStatus}
                        startIcon={<Icon>save</Icon>}
                    >
                        {trans("global.button.save")}
                    </LoadingButton>
                </Stack>
            </ModalActions>
        </Modal>
    );
};

export default PotentialClientsStatusModal;
